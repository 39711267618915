import React, { useEffect } from "react";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { executeQuery } from "./helperFunctions";
import { schema } from "./form_schemas/user";
import UserActionMenu from "./UserActionMenu";
import CollapsibleTableRow from "./CollapsibleTableRow";

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    fontWeight: "bold",
  },
}));

const UserTable = ({ users, setUsers, ...rest }) => {
  const classes = useStyles();
  const headerFields = ["name"];
  const fields = ["email", "name", "role", "active", "avatar"];

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const res = await executeQuery({
          query: {
            users: {
              _id: true,
              name: true,
              email: true,
              role: true,
              active: true,
              avatar: {
                url: true,
              },
            },
          },
        });
        setUsers(res.users);
      } catch (error) {
        console.log(error?.message);
      }
    };

    fetchUsers();
  }, [setUsers]);

  const updateUserDetails = async (data, onSuccess, onFail) => {
    try {
      const res = await executeQuery({
        mutation: {
          updateUserDetails: {
            __args: {
              userInput: {
                _id: data._id,
                name: data.name,
                email: data.email,
                role: data.role,
                active: data.active,
                avatar: data.avatar,
              },
            },
            _id: true,
            name: true,
            email: true,
            role: true,
            active: true,
            avatar: {
              url: true,
            },
          },
        },
      });
      const user = res.updateUserDetails;
      onSuccess(user);
    } catch (error) {
      onFail(error?.message);
    }
  };

  const updateUserArray = (updatedUser) => {
    const idx = users.findIndex((user) => updatedUser._id === user._id);
    setUsers([...users.slice(0, idx), updatedUser, ...users.slice(idx + 1)]);
  };

  return (
    <Table {...rest}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableHeader}></TableCell>
          {headerFields.map((fieldName) => (
            <TableCell key={fieldName} className={classes.tableHeader}>
              {schema[fieldName].label}
            </TableCell>
          ))}
          <TableCell className={classes.tableHeader} align="right">
            Actions
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {users.map((user) => (
          <CollapsibleTableRow
            key={user._id}
            collapseContent={
              <Table size="small">
                <TableBody>
                  {fields.map((fieldName) => (
                    <TableRow key={fieldName}>
                      <TableCell className={classes.tableHeader}>
                        {schema[fieldName].label}
                      </TableCell>

                      <TableCell>{schema[fieldName].toString(user)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            }
          >
            {headerFields.map((fieldName) => (
              <TableCell key={fieldName}>
                {schema[fieldName].toString(user)}
              </TableCell>
            ))}
            <TableCell align="right">
              <UserActionMenu
                fields={fields}
                user={user}
                onSubmit={(reqData, onSuccess, onFail) =>
                  updateUserDetails(
                    reqData,
                    (resData) => {
                      onSuccess(resData);
                      updateUserArray(user);
                    },
                    onFail
                  )
                }
              />
            </TableCell>
          </CollapsibleTableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default UserTable;
