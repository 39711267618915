import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import ErrorPage from "./ErrorPage";
import RecipeViewPage from "./RecipeViewPage";

const RecipeRouter = ({ recipeSlugs, ...rest }) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      {recipeSlugs.map((recipe) => (
        <Route key={recipe._id} path={`${path}/${recipe.slug}`}>
          <RecipeViewPage _id={recipe._id} />
        </Route>
      ))}
      <Route>
        <ErrorPage />
      </Route>
    </Switch>
  );
};

export default RecipeRouter;
