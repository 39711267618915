import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import RatingIcon1 from "@material-ui/icons/LooksOne";
import RatingIcon2 from "@material-ui/icons/LooksTwo";
import RatingIcon3 from "@material-ui/icons/Looks3";
import RatingIcon4 from "@material-ui/icons/Looks4";
import RatingIcon5 from "@material-ui/icons/Looks5";
import { DIFFICULTY_LABELS } from "./constants";

const useStyles = makeStyles((theme) => ({
  rating: {
    marginRight: theme.spacing(2),
  },
  difficultyBox: {
    marginBottom: 0,
  },
  label: {
    fontFamily: ['"Montserrat"', "sans-serif"],
    fontWeight: 400,
    textTransform: "uppercase",
  },
  ratingRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  ratingRowWithHeading: {
    marginTop: theme.spacing(2),
  },
  ratingIcon: {
    fontSize: "inherit",
  },
}));

const StyledRating = withStyles((theme) => ({
  iconFilled: {
    color: theme.palette.primary.main,
  },
  iconHover: {
    color: theme.palette.primary.main,
  },
}))(Rating);

const DifficultyRating = ({ value, onChange }) => {
  const classes = useStyles();

  const ratingIcons = {
    1: {
      icon: <RatingIcon1 className={classes.ratingIcon} />,
      label: DIFFICULTY_LABELS[1],
    },
    2: {
      icon: <RatingIcon2 className={classes.ratingIcon} />,
      label: DIFFICULTY_LABELS[2],
    },
    3: {
      icon: <RatingIcon3 className={classes.ratingIcon} />,
      label: DIFFICULTY_LABELS[3],
    },
    4: {
      icon: <RatingIcon4 className={classes.ratingIcon} />,
      label: DIFFICULTY_LABELS[4],
    },
    5: {
      icon: <RatingIcon5 className={classes.ratingIcon} />,
      label: DIFFICULTY_LABELS[5],
    },
  };
  const RatingIconContainer = (props) => {
    const { value, ...other } = props;
    return <span {...other}>{ratingIcons[value].icon}</span>;
  };

  RatingIconContainer.propTypes = {
    value: PropTypes.number.isRequired,
  };

  return (
    <Box
      component="fieldset"
      mb={3}
      borderColor="transparent"
      className={classes.difficultyBox}
    >
      {onChange !== undefined && (
        <InputLabel shrink className={classes.insetLabel}>
          Difficulty Level
        </InputLabel>
      )}
      <Box
        className={clsx(
          classes.ratingRow,
          onChange !== undefined ? classes.ratingRowWithHeading : classes.none
        )}
      >
        <StyledRating
          precision={1}
          value={value || 0}
          onChange={(event, newValue) => {
            onChange(newValue);
          }}
          name="difficulty"
          size={onChange !== undefined ? "large" : "medium"}
          readOnly={onChange === undefined}
          // onChange={(event, newValue) => {
          //   setValue(newValue);
          // }}
          getLabelText={(value) => ratingIcons[value].label}
          IconContainerComponent={RatingIconContainer}
          className={classes.rating}
        />
        <Typography className={classes.label}>
          {value ? ratingIcons[value].label : ""}
        </Typography>
      </Box>
    </Box>
  );
};
DifficultyRating.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func,
};

export default DifficultyRating;
