import React, { useState, useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core";
import LightIcon from "@material-ui/icons/WbSunnyOutlined";
import DarkIcon from "@material-ui/icons/NightsStayOutlined";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import AddIcon from "@material-ui/icons/Add";
import HomeIcon from "@material-ui/icons/Home";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import SideMenu from "./SideMenu";
import { UserDataContext } from "./UserDataContext";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    color: "white",
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(18),
    },
  },
  white: {
    color: "white",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  titleLink: {
    color: "white",
    // cursor: "pointer",
    fontFamily: '"Ledger"',
    textDecoration: "none",
    fontWeight: 400,
    // fontSize: 25,
  },
  subtitle: {
    color: "#3c3737",

    fontFamily: ['"Montserrat"', "sans-serif"],
    fontWeight: 500,
    textTransform: "uppercase",
    fontSize: 15,
    textAlign: "center",
    marginTop: -4,
  },
}));

const TopBar = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const { userData, updateSelfUserPreferences } = useContext(UserDataContext);

  // const { logout } = useContext(UserDataContext);
  // const history = useHistory();
  // const findIsRecipe = () => {

  //   console.log("history", history, history.location);
  //   return history?.location?.pathname !== "/";

  // };
  // const [isRecipe, setIsRecipe] = useState(findIsRecipe);
  // useEffect(() => {
  //   const isRecipe = history.location.pathname !== "/";
  //   console.log("isRecipe", isRecipe, history);
  // }, [history]);
  // history.o

  const setThemeMode = (t) =>
    updateSelfUserPreferences(
      { ...userData.preferences, theme: t },
      () => {},
      () => {}
    );
  const dark = theme.palette.type === "dark";

  return (
    <>
      <SideMenu open={sideMenuOpen} setOpen={setSideMenuOpen} />
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            onClick={() => setSideMenuOpen(true)}
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.grow} />
          <Typography variant="h5" noWrap>
            <Link className={classes.titleLink} to={`/`}>
              <div>Randle Recipes</div>
              <div className={classes.subtitle}>Cook, Share, Love</div>
            </Link>
          </Typography>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Tooltip title="About">
              <IconButton
                component={Link}
                to={`/about`}
                className={classes.white}
              >
                <InfoIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Light / Dark">
              <IconButton
                onClick={() => setThemeMode(dark ? "light" : "dark")}
                className={classes.white}
              >
                {dark ? <LightIcon /> : <DarkIcon />}
              </IconButton>
            </Tooltip>

            <Tooltip title="New Recipe">
              <IconButton
                component={Link}
                to={`/create-recipe`}
                className={classes.white}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Home">
              <IconButton component={Link} to={`/`} className={classes.white}>
                <HomeIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              component={Link}
              to={`/create-recipe`}
              className={classes.white}
            >
              <AddIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default TopBar;
