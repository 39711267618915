import React, { useState, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import ls from "local-storage";
// import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import RecipeForm from "./RecipeForm";
import RecipeView from "./RecipeView";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
// import "@fontsource/open-sans";
// import { executeQuery } from "./helperFunctions";
import { UserDataContext } from "./UserDataContext";

import "./RecipeAdmin.css";
import { LS_FORM_KEY, BLANK_RECIPE } from "./constants";
import { RecipeIndexContext } from "./RecipeIndexContext";
import { useHistory } from "react-router";

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: 0,
    boxSizing: "border-box",
    // backgroundColor: theme.palette.background.paper,
    // color: theme.palette.text.primary,
  },
  container: {
    padding: theme.spacing(5, 0, 0, 0),
    // backgroundColor: "white",
  },
  hidden: {
    display: "none",
  },
  loadIcon: {
    color: "white",
  },
  recipeBtnArea: {
    display: "flex",
    textAlign: "center",
    // backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1),
    width: "100%",

    justifyContent: "center",
    position: "fixed",
    // width: "100%",
    left: 0,
    backgroundColor: grey[theme.palette.type === "dark" ? 700 : 200],
    paddingTop: theme.spacing(1),
    marginTop: theme.spacing(2),
    zIndex: 1000,

    [theme.breakpoints.up("xs")]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(3),
    },
  },
  snackbar: {
    bottom: 60,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  revertBtn: {
    marginRight: theme.spacing(2),
  },
  appBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
}));

const RecipeAdmin = ({
  // dark,
  // setThemeMode,
  // switchToFrontEnd,
  // editRecipe,
  // switchToNewRecipe,
  recipe,
}) => {
  const classes = useStyles();
  // console.log("editRecipe", editRecipe);
  const [feedbackMsg, setMessage] = useState([false, "", ""]);
  const [checkRevert, setCheckRevert] = useState(false);
  const [clash, setClash] = useState(false);
  const [changes, setChanges] = useState({});
  const { userData } = useContext(UserDataContext);
  const { createRecipe, editRecipe } = useContext(RecipeIndexContext);
  const history = useHistory();

  // console.log("aaa recipe", recipe);
  const initState = () => ({
    ...BLANK_RECIPE,
    authorId: recipe.author?._id || userData._id,
    ...(recipe._id ? recipe : BLANK_RECIPE),
    ...((ls(LS_FORM_KEY) && ls(LS_FORM_KEY)[recipe._id || "new"]) || {}),
    ...changes,
  });
  const state = initState();
  // console.log("aaa state", state, state.authorId);
  // const [state, setState] = useState(initState);
  // console.log(
  //   "state",
  //   state,
  //   (ls(LS_FORM_KEY) && ls(LS_FORM_KEY)[recipe._id || "new"]) || {}
  // );
  // console.log("initState", initState());
  // const initState = () => {
  //   if (editRecipe && ls(LS_FORM_KEY) && ls(LS_FORM_KEY)[editRecipe])
  //     return ls(LS_FORM_KEY)[editRecipe];
  //   return BLANK_RECIPE;
  // };
  // const [state, setState] = useState(initState);
  const [tab, setTab] = React.useState(0);
  // const [loaded, setLoaded] = React.useState(editRecipe ? false : true);
  // console.log("loaded", editRecipe, loaded);
  // const handleTabChange = (event, newValue) => {
  //   setTab(newValue);
  // };
  const formatRecipeForSaving = (r) => {
    const forSaving = {
      ...r,
      sections: (r.sections || []).map((s) => ({ photos: [], ...s })),
    };
    if (forSaving.sections.length === 0) delete forSaving.sections; // old data
    if (forSaving.photo) delete forSaving.photo; // old data
    if (Object.keys(forSaving).length === 0) return false;
    return forSaving;
  };
  const setStateWithSave = useCallback(
    (s) => {
      const currentStore = ls(LS_FORM_KEY) || {};
      const newState = formatRecipeForSaving(
        typeof s === "function" ? s(state) : s
      );
      ls(LS_FORM_KEY, {
        ...currentStore,
        [state._id || "new"]: newState,
      });
      setChanges(newState);
      // setState(s);
    },
    [state]
  );

  // useEffect(() => {
  //   // console.log("uE1", editRecipe, state, setStateWithSave, loaded);
  //   const fetchRecipe = async () => {
  //     console.log("FETCHIN");
  //     try {
  //       const res = await executeQuery({
  //         query: {
  //           oneRecipe: {
  //             __args: {
  //               recipeId: editRecipe,
  //             },
  //             ...RECIPE_QUERY_FIELDS,
  //           },
  //         },
  //       });
  //       const item = formatRecipe(res.oneRecipe);
  //       // console.log("sections", item.sections);
  //       // item.sections = item.sections.map((section) => ({
  //       //   ...section,
  //       //   method: JSON.parse(section.method),
  //       // }));
  //       // console.log("sections2", item.sections);
  //       if (item) {
  //         setLoaded(true);
  //         console.log("ITEM yeah", item, state.updatedAt, item.updatedAt);
  //         if (editRecipe && (!state._id || state._id !== editRecipe)) {
  //           setStateWithSave(item);
  //         } else if (
  //           state.updatedAt &&
  //           item.updatedAt &&
  //           state.updatedAt !== item.updatedAt
  //         ) {
  //           console.log("CLASH", state, item);
  //           setClash(item);
  //         }
  //         // else {
  //         //   setStateWithSave(item);
  //         // }
  //       }
  //       // console.log(
  //       //   "fetchRecipe",
  //       //   editRecipe,
  //       //   getById(res.recipes, editRecipe)
  //       // );
  //     } catch (error) {
  //       console.log(error?.message);
  //     }
  //   };
  //   if (!loaded && editRecipe) fetchRecipe();
  //   // don't fetch of the local storage data has the same ID as the one you're about to fetch
  //   // if (editRecipe && (!state._id || state._id !== editRecipe)) {
  //   //   fetchRecipe();
  //   // } else {
  //   //   setLoaded(true);
  //   // }
  // }, [editRecipe, state, setStateWithSave, loaded]);

  // const resetRecipe = useCallback(() => {
  //   ls(LS_FORM_KEY, false);
  //   setState(BLANK_RECIPE);
  // }, []);

  const validRecipe =
    state.title &&
    state.category &&
    (state.sections || []).length > 0 &&
    (state.sections || []).every((s) => s.method);

  const recipeBtnArea = (
    <Paper className={classes.recipeBtnArea} elevation={3}>
      {state._id && (
        <Button
          variant="outlined"
          // color="secondary"
          onClick={() => setCheckRevert(true)}
          className={classes.revertBtn}
        >
          Discard
        </Button>
      )}
      {/* {state._id && (
        <Button
          variant="outlined"
          // color="secondary"
          onClick={() => setTab(tab === 0 ? 1 : 0)}
          className={classes.revertBtn}
        >
          {tab === 0 ? "Preview" : "Edit"}
        </Button>
      )} */}

      <ButtonGroup className={classes.revertBtn}>
        <Button
          variant="outlined"
          onClick={() => {
            setTab(0);

            setTimeout(() => window.scrollTo(0, 0), 100);
          }}
          // className={classes.revertBtn}
          disabled={tab === 0}
        >
          Edit
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            setTab(1);

            setTimeout(() => window.scrollTo(0, 0), 100);
          }}
          disabled={tab === 1}
        >
          Preview
        </Button>
      </ButtonGroup>
      <Button
        variant="contained"
        color="secondary"
        onClick={async () => {
          if (!validRecipe) return false;
          // console.log(recipe);

          // format recipe before sending to server
          const {
            // fields to remove
            slug,
            photo,
            dietaryNames,
            categoryName,
            authorId,
            authorName,
            authorAvatar,
            updatedAt,
            createdAt,
            creator,
            ...recipeInput
          } = state;

          const formattedRecipeInput = {
            ...recipeInput,
            // fields to add
            author: state.authorId,
          };

          // console.log("aaa recipeInput", formattedRecipeInput, authorId);

          try {
            let res;
            if (!state._id) {
              res = await createRecipe(
                formattedRecipeInput,
                (newRecipe) => history.push(`recipe/${newRecipe.slug}/edit`),
                console.log
              );
            } else {
              res = await editRecipe(
                formattedRecipeInput,
                () => {},
                console.log
              );
            }
            // ls(LS_FORM_KEY, false);
            // resetRecipe();
            console.log(res);
            if (res?.createRecipe?._id)
              setStateWithSave((r) => ({ ...r, _id: res?.createRecipe?._id }));
            setMessage([
              true,
              res?.createRecipe ? "Recipe added!" : "Recipe updated!",
              "success",
            ]);
            if (res?.createRecipe) {
              const currentStore = ls(LS_FORM_KEY);
              ls(LS_FORM_KEY, {
                ...currentStore,
                new: false,
              });
              console.log("New recipe, clear local storage", ls("new"));
            }
          } catch (e) {
            setMessage([
              true,
              `Error: ${e?.message || "Problem saving recipe."}`,
              "error",
            ]);
          }
        }}
        disabled={!validRecipe}
      >
        {state._id ? "Save" : "Publish"}
      </Button>
      <Snackbar
        open={feedbackMsg && feedbackMsg[0]}
        autoHideDuration={6000}
        onClose={() => setMessage((m) => [false, m[1], m[2]])}
        className={classes.snackbar}
      >
        <Alert
          onClose={() => setMessage((m) => [false, m[1], m[2]])}
          severity={feedbackMsg[2]}
        >
          {feedbackMsg[1]}
        </Alert>
      </Snackbar>
    </Paper>
  );
  return (
    <>
      <div className={classes.root}>
        {/* <AppBar position="fixed" className={classes.appBar}>
          <Tabs value={tab} onChange={handleTabChange} centered>
            <Tab label="Edit" />
            <Tab label="Preview" />
          </Tabs>
          <div>
            <IconButton onClick={() => setThemeMode(dark ? "light" : "dark")}>
              {dark ? (
                <LightIcon className={classes.loadIcon} />
              ) : (
                <DarkIcon className={classes.loadIcon} />
              )}
            </IconButton>
            <IconButton onClick={switchToFrontEnd}>
              <HomeIcon className={classes.loadIcon} />
            </IconButton>
          </div>
        </AppBar> */}
        <div className={classes.container}>
          <div className={tab === 0 ? classes.none : classes.hidden}>
            {recipeBtnArea}
            <RecipeForm
              state={state}
              setState={setStateWithSave}
              // switchToNewRecipe={switchToNewRecipe}
            />
          </div>
          <div className={tab === 1 ? classes.none : classes.hidden}>
            {recipeBtnArea}
            <RecipeView
              recipe={state}
              editMode={true}
              // resetRecipe={resetRecipe}
              // setRecipeId={(_id) => setState((r) => ({ ...r, _id }))}
            />
          </div>
        </div>
      </div>
      <Dialog open={clash} onClose={() => setClash(false)}>
        <DialogTitle>Data clash!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            There's new data from the server that's different from your local
            changes.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setClash(false)}
            color="primary"
            variant="contained"
          >
            Keep Local
          </Button>
          <Button
            onClick={() => {
              setStateWithSave(clash);
              setClash(false);
            }}
            color="primary"
            variant="contained"
          >
            Overwrite from server
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={checkRevert}
        onClose={() => setCheckRevert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Lose any unsaved data?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Reverting will delete any local changes to this recipe that you
            haven't saved. Are you sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setCheckRevert(false)}
            color="primary"
            variant="contained"
          >
            No
          </Button>
          <Button
            onClick={() => {
              const currentStore = ls(LS_FORM_KEY);
              console.log("currentStore", state._id, currentStore);
              ls(LS_FORM_KEY, {
                ...currentStore,
                [state._id]: {},
              });
              console.log("currentStore2", ls(LS_FORM_KEY));
              setCheckRevert(false);
              // setState(initState);
              setStateWithSave({});
            }}
            color="primary"
            variant="contained"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
RecipeAdmin.propTypes = {
  // dark: PropTypes.bool.isRequired,
  // setThemeMode: PropTypes.func.isRequired,
  // switchToFrontEnd: PropTypes.func.isRequired,
  // switchToNewRecipe: PropTypes.func.isRequired,
  // editRecipe: PropTypes.string,
  recipe: PropTypes.object,
};

export default RecipeAdmin;
