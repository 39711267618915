import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { Edit, MoreVert, Replay } from "@material-ui/icons";
import { useState } from "react";
import { schema } from "./form_schemas/user";
import DialogForm from "./DialogForm";
import ConfirmPasswordReset from "./ConfirmPasswordReset";

const UserActionMenu = ({ fields, user, onSubmit, ...rest }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [editUserOpen, setEditUserOpen] = useState(false);
  const [resetPasswordOpen, setResetPasswordOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton {...rest} onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            setEditUserOpen(true);
            handleClose();
          }}
        >
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <ListItemText>Update user details</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setResetPasswordOpen(true);
            handleClose();
          }}
        >
          <ListItemIcon>
            <Replay />
          </ListItemIcon>
          <ListItemText>Reset user password</ListItemText>
        </MenuItem>
      </Menu>
      <DialogForm
        open={editUserOpen}
        onClose={() => setEditUserOpen(false)}
        title="Edit User"
        fields={fields}
        schema={schema}
        initialValues={user}
        buttonText="submit"
        onSubmit={onSubmit}
      />
      <ConfirmPasswordReset
        open={resetPasswordOpen}
        onClose={() => setResetPasswordOpen(false)}
        user={user}
      />
    </>
  );
};

export default UserActionMenu;
