import { useEffect, useState } from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import { RECIPE_QUERY_FIELDS } from "./constants";
import ErrorPage from "./ErrorPage";
import { executeQuery, formatRecipe } from "./helperFunctions";
import RecipeAdmin from "./RecipeAdmin";
import RecipeView from "./RecipeView";

const RecipeViewPage = ({ _id, ...rest }) => {
  const [recipe, setRecipe] = useState(false);
  const { path } = useRouteMatch();
  console.log("RECIPE", recipe);

  useEffect(() => {
    const fetchRecipe = async () => {
      try {
        const res = await executeQuery({
          query: {
            oneRecipe: {
              __args: {
                recipeId: _id,
              },
              ...RECIPE_QUERY_FIELDS,
            },
          },
        });
        setRecipe(formatRecipe(res.oneRecipe));
      } catch (error) {
        console.log(error?.message);
      } finally {
      }
    };
    fetchRecipe();
  }, [_id]);

  return (
    <Switch>
      <Route path={`${path}`} exact>
        {recipe === false ? (
          <RecipeView recipe={{}} loading={true} />
        ) : (
          <RecipeView recipe={recipe} />
        )}
      </Route>
      <Route path={`${path}/edit`}>
        <RecipeAdmin recipe={recipe || {}} />
      </Route>
      <Route>
        <ErrorPage />
      </Route>
    </Switch>
  );
};

export default RecipeViewPage;
