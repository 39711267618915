import React, {
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { useHistory } from "react-router";
// import PropTypes from "prop-types";
import clsx from "clsx";
import α from "color-alpha";
import ls from "local-storage";
import { makeStyles, fade, useTheme } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Paper from "@material-ui/core/Paper";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MoreIcon from "@material-ui/icons/ChevronRight";
import IconButton from "@material-ui/core/IconButton";
import LeftIcon from "@material-ui/icons/ArrowBack";
import RightIcon from "@material-ui/icons/ArrowForward";
import Star from "@material-ui/icons/Star";
import StarBorder from "@material-ui/icons/StarBorder";
import Zoom from "@material-ui/core/Zoom";
import Fab from "@material-ui/core/Fab";
import JumpToTopIcon from "@material-ui/icons/Search";
// import RefreshIcon from "@material-ui/icons/Refresh";
// import "@fontsource/open-sans";
import { UserDataContext } from "./UserDataContext";
import { RecipeIndexContext } from "./RecipeIndexContext";
import {
  // executeQuery,
  // uniqueArray,
  // uniqueArrayById,
  // sortByName,
  sortByTitle,
  // shuffleArray,
} from "./helperFunctions";
// import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import useViewport from "./useViewport";
import RecipeCard from "./RecipeCard";

const GRID_HEIGHT = 360;
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: 0, // theme.spacing(0, 2, 6, 2),
    boxSizing: "border-box",
    backgroundColor: theme.palette.type === "dark" ? "#565656" : "#edecea",
    // color: theme.palette.text.primary,
  },
  container: {
    padding: theme.spacing(6, 2, 4, 2),

    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(8, 2, 4, 2),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(8, 9.5, 4, 9.5),
    },
    // maxWidth: 960,
    margin: "0 auto",
  },
  hidden: {
    display: "none",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.text.primary, 0.05),
    "&:hover": {
      backgroundColor: fade(theme.palette.text.primary, 0.15),
    },
    // marginRight: theme.spacing(2),
    // marginLeft: 0,
    // width: "100%",
    // display: "none",
    // [theme.breakpoints.up("sm")]: {
    // marginLeft: theme.spacing(3),
    // width: "auto",
    // display: "block",
    // },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    // [theme.breakpoints.up("md")]: {
    //   width: "40ch",
    // },
  },
  horiz: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    // overflow: "hidden",
    position: "relative",
    // width: "100%",
    // backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    scrollBehavior: "smooth",
    MsOverflowStyle: "none" /* Internet Explorer 10+ */,
    scrollbarWidth: "none" /* Firefox */,
    // paddingRight: theme.spacing(10),
    "&::-webkit-scrollbar": {
      display: "none" /* Safari and Chrome */,
    },
  },
  gridListFiltered: {
    flexWrap: "wrap",
  },
  fullGridTitle: {
    // color: theme.palette.primary.light,
    fontSize: "1.2rem",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
  },
  gridTitleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.4) 70%, rgba(0,0,0,0) 100%)",
  },
  content: {
    marginTop: theme.spacing(4),

    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(14),
    },
  },
  sectionTitle: {
    margin: theme.spacing(4, 0, 2, 0),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    "&:hover $exploreIcon": {
      left: theme.spacing(1),
      transform: "scale(2)",
    },
  },
  explore: {
    fontSize: 12,
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    display: "flex",
    fontFamily: ['"Montserrat"', "sans-serif"],
    fontWeight: 700,
    textTransform: "uppercase",
    color: theme.palette.secondary.main,
    alignItems: "center",
    whiteSpace: "nowrap",
  },
  exploreIcon: {
    fontSize: "inherit",
    position: "relative",
    left: 0,
    transition: "0.5s left, 0.5s transform",
  },
  flag: {
    marginLeft: theme.spacing(1),
  },
  tile: {
    // cursor: "pointer",
    textDecoration: "none",
    height: "auto",

    fontWeight: 700,
    // "&:hover": {

    // },
    "&:hover $recipeCardBackground": {
      // width: "calc(100% + 16px)",
      // marginLeft: -8,
      // height: 256,
      // marginTop: -8,
      width: "104%",
      marginLeft: "-2%",
      height: "104%",
      marginTop: "-2%",
    },
  },
  tileTile: {
    overflow: "visible",
  },
  recipeCardBackground: {
    // opacity: 0.9,
  },
  white: {
    color: "white",
  },
  gold: {
    color: "gold",
  },
  filters: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    marginTop: theme.spacing(2),
    backgroundColor: "transparent",

    [theme.breakpoints.up("md")]: {
      justifyContent: "center",
      position: "fixed",
      width: "100%",
      left: 0,
      backgroundColor: grey[theme.palette.type === "dark" ? 700 : 200],
      paddingTop: theme.spacing(1),
      marginTop: 0,
      zIndex: 1000,
    },
  },
  filter: {
    margin: theme.spacing(0, 1, 1, 0),
    minWidth: 200,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
  starFilter: {
    margin: theme.spacing(0, 1, 1, 0),
  },
  resetBtn: {
    margin: theme.spacing(0, 1, 1, 0),
  },
  formControl: {
    margin: theme.spacing(0, 1),
  },
  filteredRecipes: {
    marginTop: theme.spacing(4),
  },
  cardMedia: {
    height: 240,
  },
  bookmarkBtn: {
    marginLeft: "auto",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  cardAction: {
    // paddingLeft: theme.spacing(1),

    fontFamily: ['"Montserrat"', "sans-serif"],
    fontWeight: 400,
    textTransform: "uppercase",
    fontSize: "0.85rem",
  },
  cardInfo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  gridBtn: {
    position: "absolute",
    zIndex: 100,
    top: (GRID_HEIGHT - 48) / 2,
    backgroundColor: α(theme.palette.secondary.main, 0.7), // "rgba(0, 0, 0, 0.5)",
    "&:hover": {
      backgroundColor: α(theme.palette.secondary.main, 0.8),
    },
    color: "white",
  },
  gridBtnDisabled: {
    opacity: 0.2,
    cursor: "default",
  },
  gridLeftBtn: {
    left: -24,
  },
  gridRightBtn: {
    right: -24,
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const RecipeIndex = () => {
  const classes = useStyles();
  const theme = useTheme();
  // const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { width } = useViewport();
  const hasFilterBar = useMediaQuery(theme.breakpoints.up("md"));
  const [section, setSection] = useState(ls("section") || "");
  const [author, setAuthor] = useState(ls("author") || "");
  const [dietary, setDietary] = useState(ls("dietary") || "");
  const [search, setSearch] = useState(ls("search") || "");
  const [filterStar, setFilterStar] = useState(ls("star") || false);
  // const [shuffledSections, setShuffledSections] = useState([]);
  // const { logout } = useContext(UserDataContext);
  const { userData } = useContext(UserDataContext);
  const { recipes, categories, dietaries, authors, sections } = useContext(
    RecipeIndexContext
  );
  // console.log("recipesData", recipesData);
  // const [recipes, setRecipes] = useState(ls("fetchedRecipes") || []);
  const gridListRefs = useRef([...Array(100).keys()].map(React.createRef));
  const [gridArrowsDisabled, setGridArrowsDisabled] = useState({
    L0: true,
    L1: true,
    L2: true,
    L3: true,
  });
  const setSectionWithLS = (s) => {
    ls.set("section", s);
    setSection(s);
  };
  const setAuthorWithLS = (a) => {
    ls.set("author", a);
    setAuthor(a);
  };
  const setDietaryWithLS = (d) => {
    ls.set("dietary", d);
    setDietary(d);
  };
  const setSearchWithLS = (s) => {
    ls.set("search", s);
    setSearch(s);
  };
  const setFilterStarWithLS = (s) => {
    ls.set("star", s);
    setFilterStar(s);
  };
  // const resetData = () => {
  //   ls.set("fetchedRecipes", []);
  //   ls.set("sections", []);
  //   setRecipes([]);
  // };
  const history = useHistory();
  console.log("history", history);
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const data = {
    categories,
    dietaries,
    authors,
  };
  // data.categories = uniqueArrayById([...recipes.map((r) => r.category)]).sort(
  //   sortByName
  // );
  // data.authors = uniqueArrayById([...recipes.map((r) => r.author)]).sort(
  //   sortByName
  // );
  // data.dietaries = uniqueArrayById(
  //   [...recipes.map((r) => r.dietaries)].flat()
  // ).sort(sortByName);
  // // console.log("data.dietaries", data.dietaries);
  // // console.log("recipes", recipes);
  // // console.log("ri recipeCard", classes.recipeCard);

  // const sections = useMemo(() => {
  //   // if (ls("sections") && ls("sections").length > 0) return ls("sections");
  //   if (shuffledSections.length > 0) return shuffledSections;
  //   const a = uniqueArrayById([
  //     ...data.categories.map((c) => ({ ...c, type: "category" })),
  //     // ...data.dietaries.map((d) => ({ ...d, type: "dietary" })),
  //   ]); // .sort(sortByName)
  //   shuffleArray(a);
  //   // ls.set("sections", a);
  //   setShuffledSections(a);
  //   return a;
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [shuffledSections, JSON.stringify(data.categories)]);

  // useEffect(() => {
  //   console.log("update grid arrows", sections);
  // }, [sections]);

  // useEffect(() => {
  //   const fetchRecipes = async () => {
  //     try {
  //       const res = await executeQuery({
  //         query: {
  //           recipes: {
  //             _id: true,
  //             slug: true,
  //             title: true,
  //             photos: true,
  //             sections: {
  //               ingredients: true,
  //             },
  //             category: {
  //               _id: true,
  //               name: true,
  //             },
  //             tags: {
  //               name: true,
  //             },
  //             dietaries: {
  //               _id: true,
  //               name: true,
  //             },
  //             // prepTime: true,
  //             // cookTime: true,
  //             activeTime: true,
  //             difficulty: true,
  //             author: {
  //               _id: true,
  //               name: true,
  //             },
  //           },
  //         },
  //       });
  //       shuffleArray(res.recipes);
  //       ls.set("fetchedRecipes", res.recipes);
  //       setRecipes(res.recipes);
  //     } catch (error) {
  //       console.log(error?.message);
  //     }
  //   };
  //   console.log("recipes.length", recipes.length);
  //   if (recipes.length === 0) fetchRecipes();
  // }, [recipes]);

  const searchFilter = useCallback(
    (r) => {
      if (!search) return true;
      const searchable = `${r.title} ${r.tags
        .map((t) => t.name)
        .join(" ")} ${r.dietaries.map((t) => t.name).join(" ")} ${(
        r.sections || []
      )
        .map((s) => (s.ingredients || []).join(" "))
        .join(" ")}`
        .toLowerCase()
        .replace("pieces", "")
        .replace("piece", "");
      // console.log("searchable", searchable);
      return search
        .toLowerCase()
        .split(" ")
        .every((s) => searchable.indexOf(s) >= 0);
    },
    [search]
  );

  const getRecipeSections = (recipes, section) =>
    recipes.filter(
      (r) =>
        (section.type === "category" && r.category._id === section._id) ||
        (section.type === "dietary" &&
          (r.dietaries || []).map((d) => d._id).indexOf(section._id) >= 0)
    );

  const filteredRecipes = useMemo(() => {
    // console.log(
    //   "filteredRecipes!",
    //   JSON.stringify(search),
    //   JSON.stringify(section)
    // );
    if (
      !search &&
      section === "" &&
      author === "" &&
      dietary === "" &&
      !filterStar
    )
      return false;
    const a = recipes
      .filter((r) => section === "" || r.category._id === section)
      .filter(
        (r) =>
          !filterStar ||
          userData.favourites.map((rec) => rec._id).indexOf(r._id) !== -1
      )
      .filter((r) => author === "" || r.author._id === author)
      .filter(
        (r) => dietary === "" || r.dietaries.some((d) => d._id === dietary)
      )
      .filter(searchFilter)
      .sort(sortByTitle);
    console.log("sorted filteredRecipes", a);
    return a;
  }, [
    search,
    section,
    recipes,
    searchFilter,
    author,
    dietary,
    filterStar,
    userData.favourites,
  ]);

  // console.log("recipes", recipes);
  // console.log("filteredRecipes", filteredRecipes);
  // console.log("gridListRefs.current", gridListRefs.current);

  const GRID_WIDTH =
    gridListRefs.current[0] && gridListRefs.current[0].current
      ? gridListRefs.current[0].current.clientWidth
      : width;
  // console.log("GRID_WIDTH", GRID_WIDTH);
  const GRID_CARD_IDEAL_WIDTH = 400;
  const GRID_SLITHER = 36;
  const GRID_COLS_B = Math.floor(GRID_WIDTH / GRID_CARD_IDEAL_WIDTH);
  const GRID_COLS =
    GRID_WIDTH < 500
      ? 1
      : Math.max(1, GRID_COLS_B + GRID_SLITHER / GRID_CARD_IDEAL_WIDTH);

  const GRID_CARD_WIDTH = GRID_WIDTH / GRID_COLS;

  // console.log("GRID_COLS", GRID_COLS, GRID_CARD_WIDTH, GRID_COLS_B);

  const updateScroll = useCallback(
    (sectionNum) => {
      const pos = gridListRefs.current[sectionNum]?.current?.scrollLeft || 0;
      const w = gridListRefs.current[sectionNum]?.current?.scrollWidth || 0;
      // console.log("updateScroll pos", pos, w, GRID_WIDTH);
      const l = `L${sectionNum}`;
      const r = `R${sectionNum}`;

      const d = {
        [l]: pos < 15,
        [r]: w - (pos + GRID_WIDTH) < 15,
      };
      if (gridArrowsDisabled[l] !== d[l] || gridArrowsDisabled[r] !== d[r])
        setGridArrowsDisabled((ad) => ({ ...ad, ...d }));
    },
    [GRID_WIDTH, gridArrowsDisabled]
  );

  const updateScrolls = useCallback(() => {
    sections.forEach((section, sectionNum) => {
      updateScroll(sectionNum);
    });
  }, [sections, updateScroll]);

  useEffect(updateScrolls, [updateScrolls]);

  const resetFilters = () => {
    setSearchWithLS("");
    setSectionWithLS("");
    setAuthorWithLS("");
    setDietaryWithLS("");
    setFilterStarWithLS(false);
    updateScrolls();
  };

  // const getTile2 = (tile) => (
  //   <GridListTile
  //     className={classes.tile}
  //     key={tile._id}
  //     cols={1}
  //     component={Link}
  //     to={`/recipe/${tile.slug}`}
  //   >
  //     <img
  //       src={
  //         (tile.photos &&
  //           tile.photos.filter((p) => p).length > 0 &&
  //           tile.photos.filter((p) => p)[0]) ||
  //         "https://carboncostume.com/wordpress/wp-content/uploads/2015/10/dennisnedry.jpg"
  //       }
  //       alt={tile.title}
  //     />
  //     <GridListTileBar
  //       title={tile.title}
  //       classes={{
  //         root: classes.gridTitleBar,
  //         title: classes.gridTitle,
  //       }}
  //       actionIcon={
  //         <IconButton
  //           aria-label={`Bookmark ${tile.title}`}
  //           onClick={(e) => {
  //             e.stopPropagation();
  //             e.preventDefault();
  //             setBookmarks((b) => [
  //               ...b.filter((a) => a !== tile._id),
  //               ...(b.indexOf(tile._id) >= 0 ? [] : [tile._id]),
  //             ]);
  //           }}
  //         >
  //           {bookmarks.indexOf(tile._id) >= 0 ? (
  //             <BookmarkedIcon className={classes.gold} />
  //           ) : (
  //             <BookmarkIcon className={classes.white} />
  //           )}
  //         </IconButton>
  //       }
  //     />
  //   </GridListTile>
  // );

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Paper className={classes.filters} elevation={hasFilterBar ? 3 : 0}>
          <div className={clsx(classes.search, classes.filter)}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder={`Search recipes…`}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
              onChange={(event) => setSearchWithLS(event.target.value)}
              value={search}
            />
          </div>

          <FormControl
            className={classes.filter}
            variant="outlined"
            size="small"
          >
            <InputLabel id="category-select-label">Category</InputLabel>
            <Select
              labelId="category-select-label"
              id="category-select"
              // value={age}
              // onChange={handleChange}
              // fullWidth
              label="Category"
              onChange={(event) => setSectionWithLS(event.target.value)}
              value={section}
              // displayEmpty
              // onChange={updateField("author")}
              // value={state.author || ""}
            >
              <MenuItem value="">
                <em>All Categories</em>
              </MenuItem>
              {/* <MenuItem value=""></MenuItem> */}
              {data.categories.map((section, sectionNum) => (
                <MenuItem value={section._id} key={section._id}>
                  {section.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            className={classes.filter}
            variant="outlined"
            size="small"
          >
            <InputLabel id="author-select-label">Author</InputLabel>
            <Select
              labelId="author-select-label"
              id="author-select"
              label="Author"
              onChange={(event) => setAuthorWithLS(event.target.value)}
              value={author}
            >
              <MenuItem value="">
                <em>All Authors</em>
              </MenuItem>
              {/* <MenuItem value=""></MenuItem> */}
              {data.authors.map((author) => (
                <MenuItem value={author._id} key={author._id}>
                  {author.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            className={classes.filter}
            variant="outlined"
            size="small"
          >
            <InputLabel id="dietary-select-label">Dietary</InputLabel>
            <Select
              labelId="dietary-select-label"
              id="dietary-select"
              label="Dietary"
              onChange={(event) => setDietaryWithLS(event.target.value)}
              value={dietary}
            >
              <MenuItem value="">
                <em>All Dietaries</em>
              </MenuItem>
              {/* <MenuItem value=""></MenuItem> */}
              {data.dietaries.map((d) => (
                <MenuItem value={d._id} key={d._id}>
                  {d.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <IconButton
            onClick={() => setFilterStarWithLS((s) => !s)}
            className={classes.starFilter}
          >
            {filterStar ? <Star /> : <StarBorder />}
          </IconButton>
          <Button
            onClick={resetFilters}
            variant="outlined"
            className={classes.resetBtn}
            // color="secondary"
            // size="small"
          >
            Reset
          </Button>
          {/* <IconButton onClick={resetData} className={classes.starFilter}>
            <RefreshIcon />
          </IconButton> */}
        </Paper>
        <div className={classes.content}>
          {!filteredRecipes && (
            <Typography className={classes.welcomeMsg} variant="h6">
              Hi, {userData.name.split(" ")[0]}! Welcome to your online family
              recipe book...
            </Typography>
          )}

          {filteredRecipes && filteredRecipes.length === 0 && (
            <Typography>No recipes found. Change the filters.</Typography>
          )}

          {filteredRecipes && filteredRecipes.length > 0 && (
            <div className={classes.filteredRecipes}>
              <GridList
                className={classes.gridListFiltered}
                // cols={tinyScreen ? 1.5 : 2.5}
                cols={Math.max(1, Math.floor(width / GRID_CARD_IDEAL_WIDTH))}
                cellHeight={GRID_HEIGHT}
                spacing={24}
              >
                {filteredRecipes.map((recipe) => (
                  <GridListTile
                    className={classes.tile}
                    key={recipe._id}
                    cols={1}
                    component={Link}
                    to={`/recipe/${recipe.slug}`}
                    classes={{
                      tile: classes.tileTile,
                    }}
                  >
                    <RecipeCard
                      recipe={recipe}
                      // className={classes.recipeCard}
                      classes={{
                        recipeCardBackground: classes.recipeCardBackground,
                      }}
                    />
                  </GridListTile>
                ))}
              </GridList>
            </div>
          )}

          {filteredRecipes === false &&
            sections.map((section, sectionNum) => (
              <div key={section._id}>
                <Typography
                  variant="h4"
                  className={classes.sectionTitle}
                  onClick={() => {
                    if (section.type === "category") {
                      setSectionWithLS(section._id);
                    } else if (section.type === "dietary")
                      setDietaryWithLS(section._id);
                    window.scrollTo({ top: 0 });
                  }}
                >
                  {section.name}
                  <span className={classes.explore}>
                    Explore All <MoreIcon className={classes.exploreIcon} />
                  </span>
                </Typography>
                <div className={classes.horiz}>
                  {Math.floor(GRID_COLS) <
                    getRecipeSections(recipes, section).length && (
                    <IconButton
                      onClick={() => {
                        if (gridArrowsDisabled[`L${sectionNum}`]) return;
                        const pos =
                          gridListRefs.current[sectionNum]?.current
                            ?.scrollLeft || 0;
                        const currentTile = Math.floor(
                          pos / GRID_CARD_WIDTH + 0.1
                        );
                        if (gridListRefs.current[sectionNum]?.current) {
                          gridListRefs.current[sectionNum].current.scrollLeft =
                            (currentTile - Math.floor(GRID_COLS)) *
                            GRID_CARD_WIDTH;
                        }
                      }}
                      className={clsx(
                        classes.gridLeftBtn,
                        classes.gridBtn,
                        gridArrowsDisabled[`L${sectionNum}`]
                          ? classes.gridBtnDisabled
                          : classes.none
                      )}
                      // disabled={gridArrowsDisabled[`L${sectionNum}`]}
                    >
                      <LeftIcon />
                    </IconButton>
                  )}
                  {Math.floor(GRID_COLS) <
                    getRecipeSections(recipes, section).length && (
                    <IconButton
                      onClick={() => {
                        if (gridArrowsDisabled[`R${sectionNum}`]) return;
                        const pos =
                          gridListRefs.current[sectionNum]?.current
                            ?.scrollLeft || 0;
                        const currentTile = Math.floor(
                          pos / GRID_CARD_WIDTH + 0.1
                        );
                        if (gridListRefs.current[sectionNum]?.current) {
                          gridListRefs.current[sectionNum].current.scrollLeft =
                            (currentTile + Math.floor(GRID_COLS)) *
                            GRID_CARD_WIDTH;
                        }
                      }}
                      className={clsx(
                        classes.gridRightBtn,
                        classes.gridBtn,
                        gridArrowsDisabled[`R${sectionNum}`]
                          ? classes.gridBtnDisabled
                          : classes.none
                      )}
                      // disabled={gridArrowsDisabled[`R${sectionNum}`]}
                    >
                      <RightIcon />
                    </IconButton>
                  )}
                  <div style={{ width: "100%" }}>
                    <GridList
                      className={classes.gridList}
                      ref={gridListRefs.current[sectionNum]}
                      // cols={tinyScreen ? 1.5 : 2.5}
                      cols={GRID_COLS}
                      cellHeight={GRID_HEIGHT}
                      spacing={24}
                      onScroll={() => updateScroll(sectionNum)}
                    >
                      {getRecipeSections(recipes, section).map((recipe) => (
                        <GridListTile
                          className={classes.tile}
                          key={recipe._id}
                          cols={1}
                          component={Link}
                          to={`/recipe/${recipe.slug}`}
                          classes={{
                            tile: classes.tileTile,
                          }}
                        >
                          <RecipeCard
                            recipe={recipe}
                            // className={classes.recipeCard}

                            classes={{
                              recipeCardBackground:
                                classes.recipeCardBackground,
                            }}
                          />
                        </GridListTile>
                      ))}
                    </GridList>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <Zoom
        in={!hasFilterBar}
        timeout={transitionDuration}
        style={{
          transitionDelay: `${!hasFilterBar ? transitionDuration.exit : 0}ms`,
        }}
        unmountOnExit
      >
        <Fab
          aria-label="Jump to Top"
          className={classes.fab}
          color="secondary"
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          <JumpToTopIcon />
        </Fab>
      </Zoom>
    </div>
  );
};
RecipeIndex.propTypes = {
  // dark: PropTypes.bool.isRequired,
  // setThemeMode: PropTypes.func.isRequired,
};

export default RecipeIndex;
