import { createValidationFunction } from "../helperFunctions";
import { FreeTextInput } from "../input_components";

const name = {
  label: "Name",
  toString: (data) => data.name,
  validate: createValidationFunction([
    {
      test: (data) => data.name !== "",
      feedback: "field is required",
    },
  ]),
  component: FreeTextInput,
  componentProps: { required: true },
};

export const schema = {
  name,
};

export const blankForm = {
  name: "",
};
