export const LS_FORM_KEY = "localRecipes";
export const BLANK_RECIPE = {
  sections: [{ title: "", intro: "", ingredients: [], method: "", photos: [] }],
  tags: [],
};
export const IMGUR_CLIENT_ID = "9cce8e2c88625b3";
export const RECIPE_IMAGE_DEFAULT = "https://i.imgur.com/hZlPLMg.jpg";
export const WEBSITE_URL = "";
export const COURSES = ["Starter", "Main", "Side", "Dessert", "Drink", "Snack"];
export const CUISINES = [
  "American",
  "British",
  "Chinese",
  "Dutch",
  "French",
  "German",
  "Greek",
  "Indian",
  "Indonesian",
  "Iranian",
  "Irish",
  "Italian",
  "Japanese",
  "Jewish",
  "Korean",
  "Malaysian",
  "Mexican",
  "Middle Eastern",
  "Moroccan",
  "New Zealand",
  "Pacific",
  "Polish",
  "Portuguese",
  "Scottish",
  "Spanish",
  "Thai",
  "Turkish",
  "Vietnamese",
  "Welsh",
];
export const DIFFICULTY_LABELS = {
  1: "Very Simple",
  2: "Easy",
  3: "Medium",
  4: "Hard",
  5: "Very Hard",
};
export const RECIPE_QUERY_FIELDS = {
  _id: true,
  title: true,
  subtitle: true,
  author: {
    _id: true,
    name: true,
    avatar: {
      url: true,
    },
  },
  creator: {
    _id: true,
  },
  slug: true,
  updatedAt: true,
  createdAt: true,
  size: true,
  difficulty: true,
  // prepTime: true,
  // cookTime: true,
  activeTime: true,
  inactiveTime: true,
  equipment: true,
  intro: true,
  tags: {
    _id: true,
    name: true,
  },
  dietaries: {
    _id: true,
    name: true,
  },
  photos: true,
  videoUrl: true,
  sections: {
    title: true,
    ingredients: true,
    method: true,
    notes: true,
    photos: true,
    videoUrl: true,
  },
  category: {
    _id: true,
    name: true,
  },
};

export const RECIPE_INDEX_QUEST_FIELDS = {
  _id: true,
  slug: true,
  title: true,
  photos: true,
  sections: {
    ingredients: true,
  },
  category: {
    _id: true,
    name: true,
  },
  tags: {
    name: true,
  },
  dietaries: {
    _id: true,
    name: true,
  },
  // prepTime: true,
  // cookTime: true,
  activeTime: true,
  difficulty: true,
  author: {
    _id: true,
    name: true,
  },
};
