import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useEffect } from "react";
import { executeQuery } from "./helperFunctions";
import { schema } from "./form_schemas/category";
import CategoryActionMenu from "./CategoryActionMenu";

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    fontWeight: "bold",
  },
}));

const CategoryTable = ({ categories, setCategories, ...rest }) => {
  const classes = useStyles();
  const fields = ["name"];

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res = await executeQuery({
          query: {
            categories: {
              _id: true,
              name: true,
              recipes: {
                _id: true,
              },
            },
          },
        });
        setCategories(res.categories);
      } catch (error) {
        console.log(error?.message);
      }
    };

    fetchCategories();
  }, [setCategories]);

  const updateCategory = async (data, onSuccess, onFail) => {
    try {
      const res = await executeQuery({
        mutation: {
          updateCategory: {
            __args: {
              categoryInput: {
                _id: data._id,
                name: data.name,
              },
            },
            _id: true,
            name: true,
            // recipes: {
            //     name: true
            // }
          },
        },
      });
      const category = res.updateCategory;
      onSuccess(category);
    } catch (error) {
      onFail(error?.message);
    }
  };

  const deleteCategory = async (data, onSuccess, onFail) => {
    try {
      const res = await executeQuery({
        mutation: {
          deleteCategory: {
            __args: {
              categoryId: data._id,
            },
            _id: true,
            name: true,
          },
        },
      });
      const category = res.deleteCategory;
      onSuccess(category);
    } catch (error) {
      onFail(error?.message);
    }
  };

  const updateCategoryArray = (updatedCategory) => {
    const idx = categories.findIndex(
      (category) => updatedCategory._id === category._id
    );
    setCategories([
      ...categories.slice(0, idx),
      updatedCategory,
      ...categories.slice(idx + 1),
    ]);
  };

  const removeCategoryFromArray = (category) => {
    setCategories(categories.filter((cat) => cat._id !== category._id));
  };

  return (
    <Table {...rest}>
      <TableHead>
        <TableRow>
          {fields.map((fieldName) => (
            <TableCell key={fieldName} className={classes.tableHeader}>
              {schema[fieldName].label}
            </TableCell>
          ))}
          <TableCell>Recipes</TableCell>
          <TableCell className={classes.tableHeader} align="right">
            Actions
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {categories.map((category) => (
          <TableRow key={category._id}>
            {fields.map((fieldName) => (
              <TableCell key={fieldName}>
                {schema[fieldName].toString(category)}
              </TableCell>
            ))}
            <TableCell>{category.recipes.length}</TableCell>
            <TableCell align="right">
              <CategoryActionMenu
                fields={fields}
                category={category}
                onSubmitEdit={(reqData, onSuccess, onFail) =>
                  updateCategory(
                    reqData,
                    (resData) => {
                      onSuccess(resData);
                      updateCategoryArray(category);
                    },
                    onFail
                  )
                }
                onSubmitDelete={(reqData, onSuccess, onFail) =>
                  deleteCategory(
                    reqData,
                    (resData) => {
                      onSuccess(resData);
                      removeCategoryFromArray(category);
                    },
                    onFail
                  )
                }
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default CategoryTable;
