import React, { useContext } from "react";
import {
  Avatar,
  Container,
  makeStyles,
  AppBar,
  Toolbar,
  Typography,
} from "@material-ui/core";
// import { createMuiTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import { UserDataContext } from "./UserDataContext";
import ContainerlessForm from "./ContainerlessForm";
import { schema } from "./form_schemas/login";
// import cover from "./img/cover.jpg";
import avatarPhoto from "./img/granmum-login.jpg";

const useStyles = makeStyles((theme) => ({
  flexColContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center", // this no work unless the flexbox is bigger
    alignItems: "center",
    marginTop: theme.spacing(4),
    position: "relative",
  },
  flexColItem: {
    marginBottom: theme.spacing(4),
    maxWidth: 300,
  },
  flexRowContainer: {
    display: "flex",
    alignItems: "center",
  },
  flexRowItem: {
    margin: theme.spacing(0, 2, 0, 0),
  },
  cover: {
    position: "absolute",
    top: 0,
  },
  content: {
    zIndex: 10,
    width: 500,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    // height: 685,
    paddingTop: theme.spacing(12),
  },
  avatar: {
    width: 180,
    height: 180,
  },
  titleWrap: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  titleLink: {
    color: "white",
    // cursor: "pointer",
    fontFamily: '"Ledger"',
    textDecoration: "none",
    fontWeight: 400,
    margin: "0 auto",
    // fontSize: 25,
  },
  subtitle: {
    color: "#3c3737",

    fontFamily: ['"Montserrat"', "sans-serif"],
    fontWeight: 500,
    textTransform: "uppercase",
    fontSize: 15,
    textAlign: "center",
    marginTop: -4,
  },
}));

const LoginPage = () => {
  const classes = useStyles();
  const { login } = useContext(UserDataContext);

  // const theme = createMuiTheme({
  //   palette: {
  //     type: "dark",
  //     primary: {
  //       main: "#FFFFFF",
  //     },
  //     secondary: {
  //       main: "#FFFFFF",
  //     },
  //   },
  // });

  return (
    <Container className={clsx(classes.flexColContainer)} maxWidth="sm">
      <AppBar position="fixed">
        <Toolbar>
          <Typography variant="h5" noWrap className={classes.titleWrap}>
            <div className={classes.titleLink}>
              <div>Randle Recipes</div>
              <div className={classes.subtitle}>Cook, Share, Love</div>
            </div>
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.content}>
        {/* <Typography className={classes.flexColItem} variant="h3">
          Log in
        </Typography> */}

        {/* <CardForm */}

        <ContainerlessForm
          fields={["email", "password"]}
          schema={schema}
          initialValues={{ email: "", password: "" }}
          buttonText="log in"
          onSubmit={login}
          className={classes.flexColItem}
        />
        <Avatar src={avatarPhoto} className={classes.avatar} />
      </div>
    </Container>
  );
};

export default LoginPage;
