import React, { useContext, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router";
import AdminConsole from "./AdminConsole";
import AdminRoute from "./AdminRoute";
import RecipeIndex from "./RecipeIndex";
import ErrorPage from "./ErrorPage";
// import { executeQuery } from "./helperFunctions";
import RecipeRouter from "./RecipeRouter";
import SettingsPage from "./SettingsPage";
import TopBar from "./TopBar";
import RecipeAdmin from "./RecipeAdmin";
import AboutPage from "./AboutPage";
import { RecipeIndexContext } from "./RecipeIndexContext";

const App = ({ darkMode, ...rest }) => {
  const { recipes, fetchRecipes } = useContext(RecipeIndexContext);

  useEffect(() => {
    fetchRecipes();
  }, [fetchRecipes]);

  return (
    <>
      <TopBar />
      <Switch {...rest}>
        <Route path="/" exact>
          <RecipeIndex darkMode={darkMode} />
        </Route>

        <Route path="/index.html" exact>
          <Redirect to="/" />
        </Route>

        <AdminRoute path="/admin" redirectPath="/">
          <AdminConsole />
        </AdminRoute>

        <Route path="/settings">
          <SettingsPage />
        </Route>

        <Route path="/about">
          <AboutPage />
        </Route>

        <Route path="/recipe">
          <RecipeRouter recipeSlugs={recipes} />
        </Route>

        <Route path="/create-recipe">
          <RecipeAdmin recipe={{}} />
        </Route>

        <Route
        // catch all no-match routes
        >
          <ErrorPage />
        </Route>
      </Switch>
    </>
  );
};

export default App;
