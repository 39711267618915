import { createValidationFunction } from "../helperFunctions";
import { SelectInput } from "../input_components";

const theme = {
  label: "Theme",
  toString: (data) => data.theme,
  validate: createValidationFunction([
    {
      test: (data) => data.theme !== "",
      feedback: "field is required",
    },
  ]),
  component: SelectInput,
  componentProps: {
    options: [
      { label: "System", value: "system" },
      { label: "Light", value: "light" },
      { label: "Dark", value: "dark" },
    ],
  },
};

export const schema = { theme };
