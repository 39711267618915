// import { findByLabelText } from "@testing-library/react";
import axios from "axios";
import { jsonToGraphQLQuery } from "json-to-graphql-query";

export const getStoredToken = () => {
  const storedToken = localStorage.getItem("token");
  if (storedToken === null) {
    return "";
  }
  return storedToken;
};

export const setStoredToken = (token) => localStorage.setItem("token", token);

export const getNameById = (a, id) => {
  const find = a.filter((b) => b._id === id);
  if (find.length !== 1) return false;
  return find[0].name;
};

export const uniqueArray = (ar) => ar.filter((v, i, a) => a.indexOf(v) === i);
export const uniqueArrayById = (arr) =>
  arr.filter((v, i, a) => a.findIndex((t) => t._id === v._id) === i);

export const embedYoutube = (videoId, className = {}) => (
  <iframe
    className={className}
    frameborder="0"
    allowfullscreen="1"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    title="YouTube video player"
    width="100%"
    height="360"
    src={`https://www.youtube-nocookie.com/embed/${videoId}?&modestbranding=1&iv_load_policy=3&loop=1&rel=0`}
    //&playlist=${videoId}=0
  ></iframe>
  // <iframe
  //   className={className}
  //   width="100%"
  //   height="360"
  //   src={`https://www.youtube.com/embed/${videoId}?&modestbranding=1&iv_load_policy=3&loop=1&rel=0`}
  //   title="YouTube video player"
  //   frameborder="0"
  //   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  //   allowfullscreen
  // ></iframe>
);

export const formatTime = (t) => {
  if ((t || 0) === 0) return "";
  const hours = Math.floor(t / 60);
  const mins = t % 60;
  return `${hours > 0 ? `${hours}H ` : ""}${mins > 0 ? `${mins}M` : ""}`;
};

export const formatRecipe = (r) => {
  console.log("formatRecipe", r);
  const item = { ...r };
  item.tags = item.tags.map((t) => t.name);
  item.dietaryNames = item.dietaries.map((t) => t.name);
  item.dietaries = item.dietaries.map((t) => t._id);
  item.categoryName = item.category.name;
  item.category = item.category._id;
  item.authorName = item.author.name;
  item.authorAvatar = item.author.avatar?.url;
  // item.author = item.author._id;
  return item;
};

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
};

export const sortAlpha = (a, b) => {
  const aName = (a || "").toLowerCase().replace(/[^a-z0-9]/g, "");
  const bName = (b || "").toLowerCase().replace(/[^a-z0-9]/g, "");
  if (aName < bName) {
    return -1;
  }
  if (aName > bName) {
    return 1;
  }
  return 0;
};
export const sortNumeric = (a, b) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};
export const sortByName = (a, b) => sortAlpha(a.name, b.name);
export const sortByTitle = (a, b) => sortAlpha(a.title, b.title);
/**
 * @param {Object} queryObject The graphql query in Object form
 * @return {Promise<Object>}
 */
export const executeQuery = async (queryObject) => {
  try {
    const queryString = jsonToGraphQLQuery(queryObject, { pretty: true });
    const res = await axios({
      method: "POST",
      url: "/graphql",
      headers: {
        "content-type": "application/json",
        Authorization: `bearer ${getStoredToken()}`,
      },
      data: { query: queryString },
    });
    return res.data.data;
  } catch (err) {
    throw err?.response?.data?.errors?.[0];
  }
};

/*

queryObject
{
  query: {
    login: {
      __args: formData,
      user: {
        name: true,
        role: true,
      },
      token: true,
    },
  },
};

return
{
  login: {
    user: {
      name: "max",
      role: "admin"
    },
    token: ghfudsilghrjakfbduialfgahjfvry3ot728ro372q8rot327q8to4g2q78o4yq7fewh,
  },
}

*/

export const createValidationFunction = (validationTests) => (data) => {
  let failFeedback;
  const passAll = !validationTests.some(({ test, feedback }) => {
    const pass = test(data);
    if (!pass) {
      failFeedback = feedback;
      return true;
    }
    return false;
  });
  return passAll || failFeedback;
};

export const getPizzaData = (categories) => ({
  title: "Pizza Napoletana",
  subtitle: "Napoli-style Margherita",
  category:
    categories.filter((c) => c.name === "Mains").length === 1
      ? categories.filter((c) => c.name === "Mains")[0]._id
      : undefined,
  author: 1,
  size: "4 pizzas",
  difficulty: 2,
  // prepTime: "30 minutes",
  // cookTime: "5 minutes",
  equipment: ["Pizza oven"],
  intro: "Specifically for the Ferrari G3 electric oven or similar.",
  tags: ["Italian"],
  dietaries: ["5fea8634694af84eaeab3d1e"], // veggie
  photos: [
    "https://www.eataly.com/wp/wp-content/uploads/2019/06/2019_05_May_EatalyFlatiron_PizzaWeeks_160-6-e1564775772282.jpg",
  ],
  sections: [
    {
      title: "Dough",
      ingredients: [
        "475g type ‘00’ or ‘0’ flour",
        "0.5g dried yeast or 1.0g fresh yeast",
        "325ml room temp filtered water",
        "15g sea salt",
      ],
      method: JSON.stringify({
        blocks: [
          {
            key: "3195c",
            text: "Make Dough",
            type: "header-two",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "6suol",
            text: "Measure ingredients accurately.",
            type: "ordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "336pi",
            text: "Dissolve the salt in the water and mix in about 10% of the flour.",
            type: "ordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "8o6fj",
            text: "Add yeast and mix into the liquid to activate.",
            type: "ordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "21np7",
            text: "Gradually add the remaining flour while mixing.",
            type: "ordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "9hfao",
            text: "Knead for 10-20 minutes by hand or use mixer",
            type: "ordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "5oe5r",
            text: "Cover (with a damp cloth) the large ball (“massa”) and rest for about 2 hours at room temperature (or longer in a fridge) for first fermentation (“puntata”)",
            type: "ordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "3lhd9",
            text: "Divide (“staglio a mano”) in to 200g balls (“panetti”)",
            type: "ordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "e5e14",
            text: "Second fermentation (“appretto”), covered, for about 6 hours at room temperature (or longer in fridge)",
            type: "ordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "1ecm2",
            text: "Make base (“disco di pasta”)",
            type: "header-two",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "3rv9f",
            text: "Dust the top of the ball before touching the top.",
            type: "ordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "dkf6v",
            text: "Carefully move the ball to a flour-dusted surface.",
            type: "ordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "73vh5",
            text: "Use your fingertips to press down on the dough ball, leaving a crust",
            type: "ordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "fh2l6",
            text: "Turn over and repeat.",
            type: "ordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "3u21v",
            text: "Use your palm to flatten the middle of the disk to make a plate",
            type: "ordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "57g4d",
            text: "Pick up the dough and use the knuckles of your index and middle fingers to stretch the dough out.",
            type: "ordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
        ],
        entityMap: {},
      }),
    },
    {
      title: "Toppings",
      ingredients: [
        "60-70g canned peeled plum tomatoes (preferably “San Marzano”), strained (maybe?) and mashed by hand or with fork + sea salt (about 1g)",
        "80-100g cow mozzarella balls",
        "A few fresh basil leaves",
        "6ml extra virgin olive oil",
        "Grated parmesan (optional)",
      ],
      method: {
        blocks: [
          {
            key: "7iimd",
            text: "Add Toppings",
            type: "header-two",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "5ent7",
            text: "Spoon on the tomato (less than you think) as a spiral, leaving 1-2cm for crusts.",
            type: "ordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "4r137",
            text: "Lightly sprinkle some grated parmesan (I prefer only a small amount).",
            type: "ordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "8mmf9",
            text: "Add mozzarella pieces. I think it works best as 4 torn chunks about the size of a 50p.",
            type: "ordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "5u2ej",
            text: "Drizzle on some olive oil (not too much, start from centre and form number “6”).",
            type: "ordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "8svr",
            text: "Add basil leaves.",
            type: "ordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "61mb4",
            text: "Cooking with G3",
            type: "header-two",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "ci59d",
            text: "Turn G3 on to 2.5 with the lid closed about 15 minutes before cooking.",
            type: "ordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "45m57",
            text: "Place pizza base on metal paddles and add toppings\n(place a smaller foil disc over the toppings only?)",
            type: "ordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "5oshv",
            text: "Turn oven to 3 (so light turns on), add pizza (slide the paddles out) & quickly close lid\n(have a 2nd person there to open lid and remove foil disc quickly to minimise the time the lid is open)",
            type: "ordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "69v6k",
            text: "Cook for 3-4 minutes, turning 90 degrees 2 or 3 times (using paddle, without opening lid fully)\n(remove toppings foil for the last 20 seconds or so?)",
            type: "ordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "ck7qv",
            text: "Place on a wire rack and leave for a minute or two.",
            type: "ordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
        ],
        entityMap: {},
      },
    },
  ],
});
