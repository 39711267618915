import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { UserDataContext } from "./UserDataContext";

const AdminRoute = ({ redirectPath, children, ...rest }) => {
  const { userData } = useContext(UserDataContext);

  return (
    <Route {...rest}>
      {userData.role === "Administrator" ? (
        children
      ) : (
        <Redirect to={redirectPath} />
      )}
    </Route>
  );
};

export default AdminRoute;
