import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { DeleteForever, Edit, MoreVert } from "@material-ui/icons";
import { useState } from "react";
import { schema } from "./form_schemas/category";
import DialogForm from "./DialogForm";
// import ConfirmDeleteCategory from "./ConfirmDeleteCategory";

const CategoryActionMenu = ({
  fields,
  category,
  onSubmitEdit,
  onSubmitDelete,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [editCategoryOpen, setEditCategoryOpen] = useState(false);
  const [deleteCategoryOpen, setDeleteCategoryOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton {...rest} onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={() => {
            setEditCategoryOpen(true);
            handleMenuClose();
          }}
        >
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <ListItemText>Update category details</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={() => {
            setDeleteCategoryOpen(true);
            handleMenuClose();
          }}
        >
          <ListItemIcon>
            <DeleteForever />
          </ListItemIcon>
          <ListItemText>delete category</ListItemText>
        </MenuItem>
      </Menu>
      <DialogForm
        open={editCategoryOpen}
        onClose={() => setEditCategoryOpen(false)}
        title="Edit Category"
        fields={fields}
        schema={schema}
        initialValues={category}
        buttonText="submit"
        onSubmit={onSubmitEdit}
        maxWidth="xs"
        fullWidth
      />
      <DialogForm
        open={deleteCategoryOpen}
        onClose={() => setDeleteCategoryOpen(false)}
        title="Delete Category?"
        fields={fields}
        disabledFields={fields}
        schema={schema}
        initialValues={category}
        buttonText="confirm delete"
        onSubmit={onSubmitDelete}
        maxWidth="xs"
        fullWidth
      />
      {/* <ConfirmDeleteCategory
        open={deleteCategoryOpen}
        onClose={() => setDeleteCategoryOpen(false)}
      /> */}
    </>
  );
};

export default CategoryActionMenu;
