import {
  Collapse,
  IconButton,
  makeStyles,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import clsx from "clsx";
import { useState } from "react";

const useStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const Row = ({ className, children, collapseContent, ...rest }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  return (
    <>
      <TableRow className={clsx(classes.root, className)} {...rest}>
        <TableCell>
          <IconButton aria-label="expand row" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        {children}
      </TableRow>

      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          // ensure bottom boorder is the correct length.
          colSpan={children[0].length + 2}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            {collapseContent}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default Row;
