import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { FileCopy } from "@material-ui/icons";
import { useState } from "react";
import DialogForm from "./DialogForm";
import { blankForm, schema } from "./form_schemas/user";
import { executeQuery } from "./helperFunctions";

const useStyles = makeStyles((theme) => ({
  spacer: {
    flexGrow: 1,
  },
  flexRowContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px 24px",
  },
  flexRowItem: { margin: "0px" },
}));

const CreateUserButton = ({ users, setUsers, ...rest }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const classes = useStyles();
  const [newUser, setNewUser] = useState();
  const [passwordOpen, setPasswordOpen] = useState(false);

  const requisitionUser = async (data, onSuccess, onFail) => {
    try {
      const res = await executeQuery({
        mutation: {
          requisitionUser: {
            __args: {
              userInput: {
                name: data.name,
                email: data.email,
                role: data.role,
                active: data.active,
              },
            },
            _id: true,
            name: true,
            email: true,
            role: true,
            active: true,
            password: true,
          },
        },
      });
      const user = res.requisitionUser;
      onSuccess(user);
    } catch (error) {
      onFail(error?.message);
    }
  };

  const copyToClipboard = (str) => {
    const el = document.createElement("textarea");
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  return (
    <>
      <Button
        onClick={() => setDialogOpen(true)}
        variant="contained"
        color="primary"
        {...rest}
      >
        create new user
      </Button>
      <DialogForm
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title="Create New User"
        fields={["email", "name", "role", "active"]}
        schema={schema}
        initialValues={blankForm}
        buttonText="submit"
        onSubmit={(formData, onSuccess, onFail) =>
          requisitionUser(
            formData,
            (resData) => {
              setUsers([resData, ...users]);
              setNewUser(resData);
              setPasswordOpen(true);
              onSuccess(resData);
            },
            onFail
          )
        }
      />
      <Dialog
        open={passwordOpen}
        onClose={() => setPasswordOpen(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogContent className={classes.flexRowContainer}>
          <DialogContentText className={classes.flexRowItem}>
            {newUser?.name}'s new password is:{" "}
          </DialogContentText>
          <DialogContentText className={classes.flexRowItem}>
            {newUser?.password}{" "}
          </DialogContentText>
          <Tooltip title="copy to clipboard">
            <IconButton
              className={classes.flexRowItem}
              onClick={() => copyToClipboard(newUser?.password)}
            >
              <FileCopy />
            </IconButton>
          </Tooltip>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateUserButton;
