import { Button } from "@material-ui/core";
import React, { useState } from "react";
import DialogForm from "./DialogForm";
import { executeQuery } from "./helperFunctions";
import { blankForm, schema } from "./form_schemas/category";

const CreateCategoryButton = ({ categories, setCategories, ...rest }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const createCategory = async (data, onSuccess, onFail) => {
    try {
      const res = await executeQuery({
        mutation: {
          createCategory: {
            __args: {
              categoryInput: {
                name: data.name,
              },
            },
            _id: true,
            name: true,
            recipes: {
              _id: true,
            },
          },
        },
      });
      const category = res.createCategory;
      onSuccess(category);
    } catch (error) {
      onFail(error?.message);
    }
  };

  return (
    <>
      <Button
        onClick={() => setDialogOpen(true)}
        variant="contained"
        color="primary"
        {...rest}
      >
        create new category
      </Button>
      <DialogForm
        maxWidth="xs"
        fullWidth
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title="Create New Category"
        fields={["name"]}
        schema={schema}
        initialValues={blankForm}
        buttonText="submit"
        onSubmit={(formData, onSuccess, onFail) =>
          createCategory(
            formData,
            (resData) => {
              setCategories([resData, ...categories]);
              onSuccess(resData);
            },
            onFail
          )
        }
      />
    </>
  );
};

export default CreateCategoryButton;
