import React, { useState, createContext, useCallback, useMemo } from "react";
import { useHistory } from "react-router";
import { executeQuery, setStoredToken } from "./helperFunctions";

export const UserDataContext = createContext();

const UserDataContextProvider = (props) => {
  const [userData, setUserData] = useState({
    loggedIn: false,
  });
  const history = useHistory();

  const userDataFields = useMemo(
    () => ({
      _id: true,
      email: true,
      name: true,
      role: true,
      favourites: {
        _id: true,
      },
      avatar: {
        url: true,
      },
      preferences: {
        theme: true,
      },
    }),
    []
  );

  const login = useCallback(
    async (formData, onSuccess, onFail) => {
      try {
        const res = await executeQuery({
          query: {
            login: {
              __args: formData,
              user: userDataFields,
              token: true,
            },
          },
        });

        const { user, token } = res.login;
        setStoredToken(token);
        setUserData({
          ...user,
          loggedIn: true,
        });

        // makes the application go to the dashboard after successful login
        history.push("/"); // unsure if this is the best way
        onSuccess();
      } catch (err) {
        if (err && err.message) onFail(err.message);
        else onFail("No error message");
      }
    },
    [history, userDataFields]
  );

  const verifyToken = useCallback(async () => {
    try {
      const res = await executeQuery({
        query: {
          verifyJwt: userDataFields,
        },
      });

      const user = res.verifyJwt;
      setUserData({
        ...user,
        loggedIn: true,
      });
    } catch (err) {}
  }, [userDataFields]);

  const logout = useCallback(() => {
    setStoredToken("");
    setUserData({
      loggedIn: false,
    });
  }, []);

  // not used for now
  // const signup = useCallback(
  //   async (formData, onSuccess, onFail) => {
  //     try {
  //       await executeQuery({
  //         mutation: {
  //           signup: {
  //             __args: { userInput: formData },
  //             email: true,
  //           },
  //         },
  //       });
  //       onSuccess();

  //       // log the user in after successful signup
  //       const { email, password } = formData;
  //       login(
  //         { email, password },
  //         () => {},
  //         () => {}
  //       );
  //     } catch (err) {
  //       onFail(err.message);
  //     }
  //   },
  //   [login]
  // );

  const updateSelfUserDetails = useCallback(
    async (data, onSuccess, onFail) => {
      try {
        const res = await executeQuery({
          mutation: {
            updateSelfUserDetails: {
              __args: {
                userInput: {
                  email: data.email,
                  name: data.name,
                  avatar: data.avatar,
                },
              },
              email: true,
              name: true,
            },
          },
        });
        const user = res.updateSelfUserDetails;
        setUserData({ ...userData, ...user });
        onSuccess(user);
      } catch (error) {
        onFail(error?.message);
      }
    },
    [userData]
  );

  const updateSelfUserPreferences = useCallback(
    async (data, onSuccess, onFail) => {
      try {
        setUserData({
          ...userData,
          preferences: { ...userData.preferences, ...data },
        });
        await executeQuery({
          mutation: {
            updateSelfUserDetails: {
              __args: {
                userInput: {
                  preferences: data,
                },
              },
              preferences: {
                theme: true,
              },
            },
          },
        });

        onSuccess();
      } catch (error) {
        onFail(error?.message);
        console.log(error?.message);
      }
    },
    [userData]
  );

  const favouriteRecipe = useCallback(
    async (data) => {
      try {
        const res = await executeQuery({
          mutation: {
            favouriteRecipe: {
              __args: {
                recipeId: data,
              },
              favourites: {
                _id: true,
              },
            },
          },
        });

        setUserData({
          ...userData,
          favourites: res.favouriteRecipe.favourites,
        });
      } catch (error) {
        console.log(error?.message);
      }
    },
    [userData]
  );

  const unfavouriteRecipe = useCallback(
    async (data) => {
      try {
        const res = await executeQuery({
          mutation: {
            unfavouriteRecipe: {
              __args: {
                recipeId: data,
              },
              favourites: {
                _id: true,
              },
            },
          },
        });

        setUserData({
          ...userData,
          favourites: res.unfavouriteRecipe.favourites,
        });
      } catch (error) {
        console.log(error?.message);
      }
    },
    [userData]
  );

  return (
    <UserDataContext.Provider
      value={{
        userData,
        setUserData,
        verifyToken,
        login,
        logout,
        updateSelfUserDetails,
        updateSelfUserPreferences,
        favouriteRecipe,
        unfavouriteRecipe,
      }}
    >
      {props.children}
    </UserDataContext.Provider>
  );
};

export default UserDataContextProvider;
