import { Backdrop, CircularProgress } from "@material-ui/core";
import React, { useState, useContext, useEffect } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { UserDataContext } from "./UserDataContext";

const AuthenticatedRoute = ({ redirectPath, children, ...rest }) => {
  const { userData, verifyToken } = useContext(UserDataContext);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    // console.log("NEW PAGE", location.pathname);
    window.scrollTo({ top: 0 });
  }, [location]);

  useEffect(() => {
    const verifyJwt = async () => {
      setLoading(true);
      await verifyToken();
      setLoading(false);
    };

    if (!userData.loggedIn) {
      verifyJwt();
    }
  }, [userData.loggedIn, verifyToken]);

  return loading ? (
    <Backdrop open>
      <CircularProgress size={120} />
    </Backdrop>
  ) : (
    <Route {...rest}>
      {userData.loggedIn ? children : <Redirect to={redirectPath} />}
    </Route>
  );
};

export default AuthenticatedRoute;
