import React, { useState, createContext, useMemo, useCallback } from "react";
import { RECIPE_INDEX_QUEST_FIELDS } from "./constants";
import {
  executeQuery,
  shuffleArray,
  uniqueArrayById,
  sortByName,
} from "./helperFunctions";

export const RecipeIndexContext = createContext();

const RecipeIndexContextProvider = (props) => {
  const [recipes, setRecipes] = useState([]);

  const fetchRecipes = useCallback(async () => {
    console.log("fetchRecipes context");
    try {
      const res = await executeQuery({
        query: {
          recipes: RECIPE_INDEX_QUEST_FIELDS,
        },
      });
      shuffleArray(res.recipes);
      // ls.set("fetchedRecipes", res.recipes);
      setRecipes(res.recipes);
    } catch (error) {
      console.log(error?.message);
    }
  }, []);

  const categories = uniqueArrayById([...recipes.map((r) => r.category)]).sort(
    sortByName
  );
  const authors = uniqueArrayById([...recipes.map((r) => r.author)]).sort(
    sortByName
  );
  const dietaries = uniqueArrayById(
    [...recipes.map((r) => r.dietaries)].flat()
  ).sort(sortByName);
  // console.log("data.dietaries", data.dietaries);
  // console.log("recipes", recipes);
  // console.log("ri recipeCard", classes.recipeCard);

  const sections = useMemo(() => {
    const a = uniqueArrayById([
      ...categories.map((c) => ({ ...c, type: "category" })),
      // ...data.dietaries.map((d) => ({ ...d, type: "dietary" })),
    ]); // .sort(sortByName)
    shuffleArray(a);
    // ls.set("sections", a);
    // setShuffledSections(a);
    return a;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(categories)]);

  //   console.log("recipes context", recipes);

  const createRecipe = useCallback(async (data, onSuccess, onFail) => {
    try {
      const res = await executeQuery({
        mutation: {
          createRecipe: {
            __args: {
              recipeInput: data,
            },
            ...RECIPE_INDEX_QUEST_FIELDS,
          },
        },
      });

      setRecipes((currentState) => [res.createRecipe, ...currentState]);
      onSuccess(res.createRecipe);
      return res;
    } catch (error) {
      onFail(error);
    }
  }, []);

  const editRecipe = useCallback(
    async (data, onSuccess, onFail) => {
      try {
        const res = await executeQuery({
          mutation: {
            updateRecipe: {
              __args: {
                recipeInput: data,
              },
              ...RECIPE_INDEX_QUEST_FIELDS,
            },
          },
        });

        const idx = recipes.findIndex(
          (recipe) => recipe._id === res.updateRecipe._id
        );
        setRecipes((currentState) => [
          ...currentState.slice(0, idx),
          res.updateRecipe,
          ...currentState.slice(idx + 1),
        ]);

        onSuccess(res.updateRecipe);
        return res;
      } catch (error) {
        onFail(error);
      }
    },
    [recipes]
  );

  const deleteRecipe = useCallback(
    async (data, onSuccess, onFail) => {
      try {
        const res = await executeQuery({
          mutation: {
            deleteRecipe: {
              __args: { recipeId: data },
              _id: true,
            },
          },
        });

        const { _id } = res.deleteRecipe;
        setRecipes(recipes.filter((recipe) => recipe._id !== _id));
        onSuccess(_id);
      } catch (err) {
        if (err && err.message) onFail(err.message);
        else onFail("No error message");
      }
    },
    [recipes]
  );

  return (
    <RecipeIndexContext.Provider
      value={{
        recipes,
        fetchRecipes,
        categories,
        authors,
        dietaries,
        sections,
        createRecipe,
        editRecipe,
        deleteRecipe,
      }}
    >
      {props.children}
    </RecipeIndexContext.Provider>
  );
};

export default RecipeIndexContextProvider;
