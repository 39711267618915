import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  IconButton,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { FileCopy } from "@material-ui/icons";
import { useState } from "react";
import { executeQuery } from "./helperFunctions";

const useStyles = makeStyles((theme) => ({
  spacer: {
    flexGrow: 1,
  },
  flexRowContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px 24px",
  },
  flexRowItem: { margin: "0px" },
}));

const ConfirmPasswordReset = ({ user, onClose, ...rest }) => {
  const [hasResetPassword, setHasResetPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  //   const [hasCopiedPassword, setHasCopiedPassword] = useState(false);
  const [newPassword, setNewPassword] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const classes = useStyles();

  const resetPassword = async (data, onSuccess, onFail) => {
    try {
      const res = await executeQuery({
        mutation: {
          resetUserPassword: {
            __args: {
              userId: data,
            },
          },
        },
      });
      onSuccess(res.resetUserPassword);
    } catch (error) {
      onFail(error?.message);
    }
  };

  const copyToClipboard = (str) => {
    const el = document.createElement("textarea");
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  return (
    <Dialog
      onClose={() => {
        !loading && onClose();
      }}
      maxWidth="xs"
      fullWidth
      {...rest}
    >
      {!hasResetPassword && (
        <>
          <DialogContent>
            <DialogContentText>Reset {user.name}'s password?</DialogContentText>
          </DialogContent>
          <Divider />
          <DialogActions>
            <div className={classes.spacer}></div>
            <Button
              color="primary"
              variant="contained"
              disabled={loading}
              onClick={() => {
                setLoading(true);
                resetPassword(
                  // data
                  user._id,
                  // onSuccess
                  (res) => {
                    setLoading(false);
                    setErrorMessage();
                    setNewPassword(res);
                    setHasResetPassword(true);
                  },
                  // onFail
                  (res) => {
                    setLoading(false);
                    setErrorMessage(res);
                  }
                );
              }}
            >
              reset
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={onClose}
              disabled={loading}
            >
              cancel
            </Button>
          </DialogActions>
        </>
      )}
      {errorMessage && (
        <>
          <Divider />
          <DialogContent>
            <DialogContentText>{errorMessage}</DialogContentText>
          </DialogContent>
        </>
      )}
      {hasResetPassword && (
        <>
          <DialogContent className={classes.flexRowContainer}>
            <DialogContentText className={classes.flexRowItem}>
              {user.name}'s new password is:{" "}
            </DialogContentText>
            <DialogContentText className={classes.flexRowItem}>
              {newPassword}{" "}
            </DialogContentText>
            <Tooltip title="copy to clipboard">
              <IconButton
                className={classes.flexRowItem}
                onClick={() => copyToClipboard(newPassword)}
              >
                <FileCopy />
              </IconButton>
            </Tooltip>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default ConfirmPasswordReset;
