import React, { useContext } from "react";
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import {
  MeetingRoom,
  Settings,
  SupervisorAccount,
  Home,
  InfoOutlined as InfoIcon,
} from "@material-ui/icons";
import { UserDataContext } from "./UserDataContext";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

const SideMenu = (props) => {
  const { setOpen, ...rest } = props;
  const classes = useStyles();
  const { userData, logout } = useContext(UserDataContext);
  // const history = useHistory();

  return (
    <Drawer {...rest} onClose={() => setOpen(false)}>
      <List className={classes.list}>
        <ListItem>
          <ListItemText>Logged in as {userData.name}</ListItemText>
        </ListItem>
        <Divider />
        <ListItem
          button
          component={Link}
          to={`/`}
          onClick={() => setOpen(false)}
        >
          <ListItemIcon>
            <Home />
          </ListItemIcon>
          <ListItemText>Home</ListItemText>
        </ListItem>

        <ListItem
          button
          component={Link}
          to={`/about`}
          onClick={() => setOpen(false)}
        >
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText>About</ListItemText>
        </ListItem>

        <ListItem
          button
          component={Link}
          to={`/settings`}
          onClick={() => setOpen(false)}
        >
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText>Settings</ListItemText>
        </ListItem>

        {userData.role === "Administrator" && (
          <ListItem
            button
            component={Link}
            to={`/admin`}
            onClick={() => setOpen(false)}
          >
            <ListItemIcon>
              <SupervisorAccount />
            </ListItemIcon>
            <ListItemText>Admin Console</ListItemText>
          </ListItem>
        )}
        <Divider />

        <ListItem
          button
          onClick={() => {
            logout();
            setOpen(false);
          }}
        >
          <ListItemIcon>
            <MeetingRoom />
          </ListItemIcon>
          <ListItemText>Log out</ListItemText>
        </ListItem>
      </List>
    </Drawer>
  );
};

export default SideMenu;
