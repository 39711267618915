const COUNTED_UNITS = [
  ["egg", "eggs"],
  ["onion", "onions"],
  ["spring onion", "spring onions"],
  ["carrot", "carrots"],
  ["tomato", "tomatoes"],
  ["potato", "potatoes"],
  ["banana", "bananas"],
  ["lime", "limes"],
  ["lemon", "lemons"],
  ["clove", "cloves"],
  ["parsnip", "parsnips"],
  ["leaf", "leaves"],
  ["handful", "handfuls"],
  ["bean", "beans"],
  ["apple", "apples"],
  ["pear", "pears"],
  ["chilli", "chillies"],
  ["plum", "plums"],
  ["bowl", "bowls"],
  ["piece", "pieces"],
  ["pack", "packs"],
  ["sachet", "sachets"],
  ["pepper", "peppers"],
  ["capsicum", "capsicums"],
  ["mushroom", "mushrooms"],
  ["packet", "packets"],
  ["box", "boxes"],
  ["bag", "bags"],
];
const decimalToFraction = (d) => {
  let out = d.toString();

  out = out
    .replaceAll(".125", "⅛")
    .replaceAll(".166", "⅙")
    .replaceAll(".333", "⅓")
    .replaceAll(".4", "⅖")
    .replaceAll(".5", "½")
    .replaceAll(".666", "⅔")
    .replaceAll(".6", "⅗")
    .replaceAll(".75", "¾")
    .replaceAll(".833", "⅚")
    .replaceAll(".8", "⅘")
    .replaceAll(".25", "¼")
    .replaceAll(".2", "⅕");
  out = out
    .split(" ")
    .map((a) => {
      return a.replaceAll(/^0([⅛⅙⅓⅖½⅔⅗¾⅚⅘¼⅕])$/g, "$1");
    })
    .join(" ");
  return out;
};
const formatDecimal = (ar) => {
  const a = parseFloat(ar);
  // console.log("formatDecimal", ar, a);
  if (a === parseInt(a)) return parseInt(a); // 8 -> 8
  if (a.toString() === a.toFixed(1) || a.toFixed(2).substr(-1) === "0")
    return a.toFixed(1); // 1.50 -> 1.5
  if (a.toString() === a.toFixed(2)) return a.toFixed(2); // 1.25 -> 1.25
  if (a < 1) return a.toFixed(2); // 0.68
  return Math.round(a); // 2
};

const formatGrams = (a) => {
  if (a < 1000) return `${formatDecimal(a)}g`;
  return `${formatDecimal(a / 1000)}kg`;
};
const formatOz = (a) => {
  if (a < 16) return `${formatDecimal(a)}oz`;
  return `${Math.floor(a / 16)}lb ${Math.round(a % 16)}oz`;
};
const formatLitres = (a) => {
  if (a < 1000) return `${formatDecimal(a)}ml`;
  return `${formatDecimal(a / 1000)}L`;
};
const MEASURED_UNITS = [
  // TODO: account for ranges in metric/imperial conversions. e.g. "10-20g flour"
  // TODO: unit array to be replaced by regex to handle matching things like "4lb 2oz"
  // {
  //   matches: [
  //     {
  //       regex: /(\d+)lb (\d+)oz/g,
  //       amount: (m) => 16 * m[1] + m[2],
  //     },
  //     {
  //       regex: /(\d+)lb/g,
  //       amount: (m) => 16 * m[1],
  //     },
  //     {
  //       regex: /(\d+)oz/g,
  //       amount: (m) => m[1],
  //     },
  //   ],
  //   format: formatOz,
  // },

  {
    unit: ["grams", "gram", "g"],
    unit1000: ["kg", "kilograms", "kilogram", "kilos", "kilo"],
    format: formatGrams,
    formatImperial: (a) => formatOz(a / 28.3495),
  },
  {
    unit: ["oz", "ounces", "ounce"],
    format: formatOz,
    formatMetric: (a) => formatGrams(a * 28.3495),
  },
  {
    unit: ["lbs", "lb", "pounds", "pound"],
    format: (a) => formatOz(a * 16),
    formatMetric: (a) => formatGrams(a * 453.592),
  },
  {
    unit: ["gallons", "gallon", "gal"],
    format: (a) => `${a} ${a > 1 ? "gallons" : "gallon"}`,
    formatMetric: (a) => formatLitres(a * 3785),
  },
  {
    unit: ["pints", "pint", "pt"],
    format: (a) => `${a} ${a > 1 ? "pints" : "pint"}`,
    formatMetric: (a) => formatLitres(a * 568.261),
  },
  {
    unit: ["ml", "millilitres", "millilitre", "milliliters", "milliliter"],
    unit1000: ["L", "litres", "litre", "liters", "liter"],
    format: formatLitres,
  },
  {
    unit: [
      "tsps",
      "tsp",
      "teaspoons",
      "teaspoon",
      "tea spoons",
      "tea spoon",
      "tbs",
    ],
    format: (a) => `${decimalToFraction(a)} tsp`,
  },
  {
    unit: [
      "tbsps",
      "tbsp",
      "tablespoons",
      "tablespoon",
      "table spoons",
      "table spoon",
    ],
    format: (a) => `${decimalToFraction(a)} tbsp`,
  },
  {
    unit: ["cups", "cup"],
    format: (a) => `${decimalToFraction(a)} ${a > 1 ? "cups" : "cup"}`,
  },
  {
    unit: ["°C", "degrees C"],
    convert: false, // TODO: leave the degrees untouched
    format: (a) => `${a}°C`,
    formatImperial: (a) => `${formatDecimal((a * 9) / 5 + 32)}°F`,
  },
  {
    unit: ["°F", "degrees F"],
    convert: false, // TODO: leave the degrees untouched
    format: (a) => `${a}°F`,
    formatMetric: (a) => `${formatDecimal(((a - 32) * 5) / 9)}°C`,
  },
  ...COUNTED_UNITS.map((unit) => ({
    unit,
    format: (a) => `${decimalToFraction(a)} ${a > 1 ? unit[1] : unit[0]}`,
  })),
];

const fractionToDecimal = (f) => {
  let out = f;
  out = out
    .split(" ")
    .map((w) => {
      if (w === "1/8") return "⅛";
      if (w === "1/6") return "⅙";
      if (w === "1/5") return "⅕";
      if (w === "1/4") return "¼";
      if (w === "1/3") return "⅓";
      if (w === "2/5") return "⅖";
      if (w === "1/2") return "½";
      // if (w === "Half") return "½";
      if (w === "3/5") return "⅗";
      if (w === "2/3") return "⅔";
      if (w === "3/4") return "¾";
      if (w === "4/5") return "⅘";
      if (w === "5/6") return "⅚";
      return w;
    })
    .join(" ");
  out = out
    .replaceAll(/((\d+)\s?)?⅛/g, "$2.125")
    .replaceAll(/((\d+)\s?)?⅙/g, "$2.166")
    .replaceAll(/((\d+)\s?)?⅕/g, "$2.2")
    .replaceAll(/((\d+)\s?)?¼/g, "$2.25")
    .replaceAll(/((\d+)\s?)?⅓/g, "$2.333")
    .replaceAll(/((\d+)\s?)?⅖/g, "$2.4")
    .replaceAll(/((\d+)\s?)?½/g, "$2.5")
    .replaceAll(/((\d+)\s?)?⅗/g, "$2.6")
    .replaceAll(/((\d+)\s?)?⅔/g, "$2.666")
    .replaceAll(/((\d+)\s?)?¾/g, "$2.75")
    .replaceAll(/((\d+)\s?)?⅘/g, "$2.8")
    .replaceAll(/((\d+)\s?)?⅚/g, "$2.833");

  return out;
};

// const formatUnit = (amount, u, fractions = false) => {
//   const formatAmount = (a) => (fractions ? decimalToFraction(a) : a);
//   if (amount >= 1000 && u.unit1000)
//     return `${formatAmount(amount / 1000)}${u.space ? " " : ""}${u.unit1000}`;
//   return `${formatAmount(amount)}${u.space ? " " : ""}${
//     amount !== 1 && u.units && !(fractions && amount < 1) ? u.units : u.unit
//   }`;
// };
export const formatIngredient = (
  ingredientRaw,
  ingredientSize = 1,
  ingredientUnit = "original"
) => {
  if (ingredientUnit === "original")
    return `${ingredientRaw} × ${ingredientSize}`;
  // return ingredientRaw;
  let ingredient = ingredientRaw;
  //   ingredient = ingredient
  //     .split(" ")
  //     .map((w) => {
  //       if (w === "1/8") return "⅛";
  //       if (w === "1/6") return "⅙";
  //       if (w === "1/5") return "⅕";
  //       if (w === "1/4") return "¼";
  //       if (w === "1/3") return "⅓";
  //       if (w === "2/5") return "⅖";
  //       if (w === "1/2") return "½";
  //       if (w === "3/5") return "⅗";
  //       if (w === "2/3") return "⅔";
  //       if (w === "3/4") return "¾";
  //       if (w === "4/5") return "⅘";
  //       if (w === "5/6") return "⅚";
  //       return w;
  //     })
  //     .join(" ");

  //   ingredient = ingredient
  //     .replaceAll(/((\d+)\s?)?⅛/g, "$2.125")
  //     .replaceAll(/((\d+)\s?)?⅙/g, "$2.166")
  //     .replaceAll(/((\d+)\s?)?⅕/g, "$2.2")
  //     .replaceAll(/((\d+)\s?)?¼/g, "$2.25")
  //     .replaceAll(/((\d+)\s?)?⅓/g, "$2.333")
  //     .replaceAll(/((\d+)\s?)?⅖/g, "$2.4")
  //     .replaceAll(/((\d+)\s?)?½/g, "$2.5")
  //     .replaceAll(/((\d+)\s?)?⅗/g, "$2.6")
  //     .replaceAll(/((\d+)\s?)?⅔/g, "$2.666")
  //     .replaceAll(/((\d+)\s?)?¾/g, "$2.75")
  //     .replaceAll(/((\d+)\s?)?⅘/g, "$2.8")
  //     .replaceAll(/((\d+)\s?)?⅚/g, "$2.833");
  // console.log("pre fractionToDecimal", ingredient);
  // const preFraction = ingredient;
  ingredient = fractionToDecimal(ingredient);
  // console.log("post fractionToDecimal", ingredient);
  // const isFraction = preFraction !== ingredient;
  // console.log("isFraction", isFraction);
  // const hasFractions = ingredient !== ingredientRaw;

  ingredient = ingredient
    .split(" ")
    .map((w) => {
      let word = w;
      let regexp = /(\d*\.?\d*)/g;
      const m = w.match(regexp);
      if (m && m.length > 0) {
        // console.log(
        //   "formatIngredient",
        //   ingredient,
        //   w,
        //   m.filter((a) => a.trim().length > 0)
        // );
        m.filter((a) => a.trim().length > 0).forEach((num) => {
          let fl = num;
          if (fl.substr(0, 1) === ".") fl = "0" + fl;
          //   console.log("numfl", num, fl);
          if (parseFloat(fl) > 0)
            word = word.replace(num, parseFloat(fl) * ingredientSize);
        });
      }
      return word.trim();
    })
    .filter((w) => w.length > 0)
    .join(" ");

  // units
  const unitRegex = /(\d+\.?\d*)\s?([^\d]+)/g;
  const unitMatch = ingredient.matchAll(unitRegex);
  [...unitMatch].forEach((m) => {
    const amount = parseFloat(m[1].trim());
    const unitText = m[2].trim();
    // console.log("unitMatch", ingredient, amount, unitText);
    MEASURED_UNITS.forEach((u) => {
      let formatted = "";
      // const useFractions =
      //   (hasFractions && u.allowFractions !== false) ||
      //   u.forceFractions === true;
      if ((u.unit || []).some((ua) => unitText.indexOf(ua) === 0)) {
        // console.log("FOUND1", u.unit, amount);
        // formatted = `${amount}${u.space ? " " : ""}${u.unit}`;

        // formatted = formatUnit(amount, u, useFractions);
        if (ingredientUnit === "metric" && u.formatMetric)
          formatted = u.formatMetric(
            amount / (u.convert === false ? ingredientSize : 1)
          );
        else if (ingredientUnit === "imperial" && u.formatImperial)
          formatted = u.formatImperial(
            amount / (u.convert === false ? ingredientSize : 1)
          );
        else if (ingredientUnit !== "original" || u.convert === false)
          formatted = u.format(
            amount / (u.convert === false ? ingredientSize : 1)
          );
      } else if (
        u.unit1000 &&
        (u.unit1000 || []).some((ua) => unitText.indexOf(ua) === 0)
      ) {
        // console.log("FOUND1000", u.unit, amount);
        // formatted = formatUnit(amount * 1000, u, useFractions);
        if (ingredientUnit === "metric" && u.formatMetric)
          formatted = u.formatMetric(amount * 1000);
        else if (ingredientUnit === "imperial" && u.formatImperial)
          formatted = u.formatImperial(amount * 1000);
        else if (ingredientUnit !== "original")
          formatted = u.format(amount * 1000);
      }
      // else if (isFraction) {
      //   // console.log(
      //   //   "NOT FOUND?",
      //   //   isFraction,
      //   //   amount,
      //   //   decimalToFraction(amount)
      //   // );
      //   formatted = decimalToFraction(amount);
      // }
      if (formatted) {
        // if (isFraction) return true;
        // console.log("FOUND", formatted);
        [...(u.unit || []), ...(u.unit1000 || [])]
          .filter((unitSearch) => (unitSearch || "").trim().length > 0)
          .some((unitSearch) => {
            // console.log(
            //   "REPLACED?",
            //   ingredient,
            //   amount,
            //   unitSearch,
            //   `${amount}\\s?${unitSearch}`,
            //   formatted
            // );
            const r = new RegExp(`${amount}\\s?${unitSearch}\\s`, "g");
            // console.log(
            //   "r.test",
            //   JSON.stringify(ingredient),
            //   amount,
            //   unitSearch
            // );
            if (!r.test(`${ingredient} `)) return false;
            ingredient = `${ingredient} `.replace(r, `${formatted} `);
            // console.log("r.test replaced", ingredient);
            return true;
          });
      }
    });
  });

  //   if (hasFractions) {
  // ingredient = ingredient
  //   .replaceAll(".125", "⅛")
  //   .replaceAll(".166", "⅙")
  //   .replaceAll(".333", "⅓")
  //   .replaceAll(".4", "⅖")
  //   .replaceAll(".5", "½")
  //   .replaceAll(".666", "⅔")
  //   .replaceAll(".6", "⅗")
  //   .replaceAll(".75", "¾")
  //   .replaceAll(".833", "⅚")
  //   .replaceAll(".8", "⅘")
  //   .replaceAll(".25", "¼")
  //   .replaceAll(".2", "⅕");
  // ingredient = ingredient
  //   .split(" ")
  //   .map((a) => {
  //     return a.replaceAll(/^0([⅛⅙⅓⅖½⅔⅗¾⅚⅘¼⅕])$/g, "$1");
  //   })
  //   .join(" ");
  //   }
  if (ingredient === ingredientRaw && ingredientSize !== 1)
    ingredient += ` × ${ingredientSize}`;
  return ingredient;
};
