import { Container, makeStyles, Typography } from "@material-ui/core";
import { useContext } from "react";
import ContainerlessForm from "./ContainerlessForm";
import { schema as userSchema } from "./form_schemas/user";
import { schema as preferencesSchema } from "./form_schemas/preferences";
import { executeQuery } from "./helperFunctions";
import { UserDataContext } from "./UserDataContext";

const useStyles = makeStyles((theme) => ({
  flexColContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(10),
  },
  flexColItem: {
    marginBottom: theme.spacing(4),
  },
  flexRowContainer: {
    display: "flex",
    alignItems: "center",
  },
  flexRowItem: {
    margin: theme.spacing(0, 2, 0, 0),
  },
}));

const SettingsPage = ({ ...rest }) => {
  const classes = useStyles();
  const { userData, updateSelfUserDetails, updateSelfUserPreferences } =
    useContext(UserDataContext);

  const changeUserPassword = async (data, onSuccess, onFail) => {
    try {
      const res = await executeQuery({
        mutation: {
          changeUserPassword: {
            __args: {
              currentPassword: data.password,
              newPassword: data.newPassword,
            },
            _id: true,
          },
        },
      });
      const user = res.updateSelfUserDetails;
      onSuccess(user);
    } catch (error) {
      onFail(error?.message);
    }
  };

  return (
    <Container className={classes.flexColContainer} maxWidth="sm" {...rest}>
      <Typography className={classes.flexColItem} variant="h3">
        Settings
      </Typography>

      <ContainerlessForm
        className={classes.flexColItem}
        title="Preferences"
        fields={["theme"]}
        schema={preferencesSchema}
        initialValues={userData.preferences}
        onSubmit={updateSelfUserPreferences}
        buttonless
      />

      <ContainerlessForm
        className={classes.flexColItem}
        title="User Details"
        fields={["email", "name", "avatar"]}
        schema={userSchema}
        initialValues={userData}
        buttonText="save changes"
        onSubmit={updateSelfUserDetails}
      />

      <ContainerlessForm
        className={classes.flexColItem}
        title="Change Password"
        fields={["password", "newPassword", "confirmNewPassword"]}
        schema={userSchema}
        initialValues={{
          password: "",
          newPassword: "",
          confirmNewPassword: "",
        }}
        buttonText="change password"
        onSubmit={changeUserPassword}
        resetOnSuccess
      />
    </Container>
  );
};

export default SettingsPage;
