import { Container, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useState } from "react";
import CategoryTable from "./CategoryTable";
import CreateCategoryButton from "./CreateCategoryButton";
import CreateUserButton from "./CreateUserButton";
import UserTable from "./UserTable";

const useStyles = makeStyles((theme) => ({
  flexColContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(10),
  },
  flexColItem: {
    marginBottom: theme.spacing(4),
  },
  flexRowContainer: {
    display: "flex",
    alignItems: "center",
  },
  flexRowItem: {
    margin: theme.spacing(0, 2, 0, 0),
  },
}));

const AdminPage = () => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [categories, setCategories] = useState([]);

  return (
    <Container className={clsx(classes.flexColContainer)} maxWidth="md">
      <Typography className={classes.flexColItem} variant="h3">
        Users
      </Typography>
      <CreateUserButton
        users={users}
        setUsers={setUsers}
        className={classes.flexColItem}
      />
      <UserTable
        size="small"
        users={users}
        setUsers={setUsers}
        className={classes.flexColItem}
      />
      <Typography className={classes.flexColItem} variant="h3">
        Categories
      </Typography>
      <CreateCategoryButton
        categories={categories}
        setCategories={setCategories}
        className={classes.flexColItem}
      />
      <CategoryTable
        size="small"
        categories={categories}
        setCategories={setCategories}
        className={classes.flexColItem}
      />
    </Container>
  );
};

export default AdminPage;
