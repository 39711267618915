import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { StarBorder, Star, Timer } from "@material-ui/icons";
import clsx from "clsx";
import { useContext } from "react";
import { DIFFICULTY_LABELS, RECIPE_IMAGE_DEFAULT } from "./constants";
import { UserDataContext } from "./UserDataContext";
import { formatTime } from "./helperFunctions";

const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
  },
  // cardActionArea: {
  //   height: "100%",
  // },
  cardAction: {
    // paddingLeft: theme.spacing(1),
    fontFamily: ['"Montserrat"', "sans-serif"],
    fontWeight: 400,
    textTransform: "uppercase",
    fontSize: "0.85rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardInfo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  cardMedia: {
    height: 240,
    // backgroundSize: "100%",
    width: "100%",
    marginLeft: 0,
    transition: "0.2s width, 0.2s margin-left, 0.2s height, 0.2s margin-top",
  },
  bookmarkBtn: {
    marginLeft: "auto",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  white: {
    color: "white",
  },
  gold: {
    color: "gold",
  },
  cardMediaContainer: {
    overflow: "hidden",
    height: 240,
  },
  cardContent: {
    height: 120,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  icon: {
    marginRight: theme.spacing(1),
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
}));

const RecipeCard = ({ recipe, className, classes: cls, ...rest }) => {
  const { userData, favouriteRecipe, unfavouriteRecipe } = useContext(
    UserDataContext
  );

  const fav =
    userData.favourites.map((rec) => rec._id).indexOf(recipe._id) !== -1;

  // console.log("recipe", recipe);
  const getDietaryShort = (d) => {
    const a = d.substr(d.indexOf(" (") + 2);
    return a.substr(0, a.indexOf(")"));
  };
  const classes = useStyles();
  return (
    <Card className={clsx(classes.card, className)} elevation={1} {...rest}>
      <CardActionArea className={classes.cardActionArea}>
        <div className={classes.cardMediaContainer}>
          <CardMedia
            className={clsx(classes.cardMedia, cls.recipeCardBackground)}
            image={
              (recipe.photos &&
                recipe.photos.filter((p) => p).length > 0 &&
                recipe.photos.filter((p) => p)[0]) ||
              RECIPE_IMAGE_DEFAULT
            }
            title={recipe.title}
          >
            <IconButton
              elevation={1}
              aria-label={`Bookmark ${recipe.title}`}
              className={classes.bookmarkBtn}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                fav
                  ? unfavouriteRecipe(recipe._id)
                  : favouriteRecipe(recipe._id);
              }}
              component="div"
            >
              {fav ? (
                <Star className={classes.gold} />
              ) : (
                <StarBorder className={classes.white} />
              )}
            </IconButton>
          </CardMedia>
        </div>
        <CardContent className={classes.cardContent}>
          <Typography variant="h5" component="h2">
            {recipe.title}
          </Typography>
          {/* <Typography variant="subtitle1" color="textSecondary">
              {recipe.author.name}
            </Typography> */}
          <div className={classes.cardInfo}>
            {/* {recipe.prepTime && (
              <Typography className={classes.cardAction}>
                Prep: {recipe.prepTime}
              </Typography>
            )} */}
            {recipe.activeTime && (
              <Typography className={classes.cardAction}>
                <Timer className={classes.icon} />
                {formatTime(recipe.activeTime)}
              </Typography>
            )}
            {recipe.difficulty && (
              <Typography className={classes.cardAction}>
                {DIFFICULTY_LABELS[recipe.difficulty]}
              </Typography>
            )}
            {recipe.dietaries && recipe.dietaries.length > 0 && (
              <Typography className={classes.cardAction}>
                {recipe.dietaries
                  .map((d) => getDietaryShort(d.name))
                  .join(" | ")}
              </Typography>
            )}
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default RecipeCard;
