import React, { useEffect, useState, useContext, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { convertToRaw, convertFromRaw, EditorState } from "draft-js";
import isUrl from "is-url";
import ls from "local-storage";
import clsx from "clsx";
import axios from "axios";
// import ImgurAnonymousUploader from "imgur-anonymous-uploader";

import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
// import Input from "@material-ui/core/Input";
// import AddIcon from "@material-ui/icons/Add";
import DragIcon from "@material-ui/icons/DragHandle";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import FormHelperText from "@material-ui/core/FormHelperText";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import MUIRichTextEditor from "mui-rte";
import LoadIcon from "@material-ui/icons/LocalPizza";
import Link from "@material-ui/core/Link";
import ListItemText from "@material-ui/core/ListItemText";
import Dropzone from "react-dropzone";
import UploadIcon from "@material-ui/icons/CloudUpload";
import LinearProgress from "@material-ui/core/LinearProgress";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import { UserDataContext } from "./UserDataContext";

import DifficultyRating from "./DifficultyRating";
import {
  executeQuery,
  getPizzaData,
  uniqueArray,
  getNameById,
} from "./helperFunctions";
import {
  LS_FORM_KEY,
  BLANK_RECIPE,
  WEBSITE_URL,
  CUISINES,
  COURSES,
  IMGUR_CLIENT_ID,
} from "./constants";
// import TextField from '@material-ui/core/TextField';
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { RecipeIndexContext } from "./RecipeIndexContext";
import { useHistory } from "react-router";
// import useMediaQuery from "@material-ui/core/useMediaQuery";
// import ListSubheader from "@material-ui/core/ListSubheader";
// import { useTheme, makeStyles } from '@material-ui/core/styles';
// import { VariableSizeList } from "react-window";
// import { Typography } from '@material-ui/core';

// const OPTIONS = ["one", "two"];
const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    maxWidth: 870,
    margin: "0px auto",
    // marginTop: theme.spacing(10),
    padding: theme.spacing(8, 2, 4, 2),
  },
  divider: {
    marginBottom: theme.spacing(3),
  },
  ingredient: {
    margin: theme.spacing(1, 0),
    fontStyle: "italic",
  },
  textField: {
    fontStyle: "italic",
    marginBottom: theme.spacing(1),
  },
  directionsHeading: {
    marginTop: theme.spacing(2),
  },
  grow: {
    flexGrow: 2,
  },
  ingredientGrid: {
    [theme.breakpoints.up("md")]: {
      width: "calc(100% + 120px)",
      marginLeft: -60,
      marginRight: -60,
    },
  },
  smallIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    fontSize: "inherit",
  },
  chip: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  chipPadding: {
    // marginLeft: theme.spacing(1),
  },
  iconGroup: {
    marginLeft: theme.spacing(2),
  },
  section: {
    paddingBottom: theme.spacing(3),
  },
  italic: {
    fontStyle: "italic",
  },
  formControl: {
    margin: theme.spacing(1, 0),
    width: "100%",
    fontStyle: "italic",
  },
  formRow: {
    display: "flex",
    flexDirection: "row",
  },
  formCol: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(2),
    "& $formRow $textField": {
      marginRight: theme.spacing(1),
      maxWidth: 100,
    },
  },
  flexGrow: {
    flexGrow: 2,
  },
  insetLabel: {
    marginLeft: theme.spacing(1),
  },
  heading: {
    marginTop: theme.spacing(6),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  headingMargin: {
    marginBottom: theme.spacing(2),
  },
  headingButton: {
    marginBottom: theme.spacing(1),
  },
  rteContainer: {
    minHeight: 90,
    marginBottom: theme.spacing(2),
  },
  helperIndent: {
    marginLeft: theme.spacing(2),
  },
  checkboxLabel: {
    marginRight: theme.spacing(4),
  },
  sectionHeading: {
    marginTop: theme.spacing(5),
  },
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  link: {
    // color: theme.palette.primary.main,
  },
  loadIcon: {
    color: theme.palette.primary.main,
  },
  recipeLink: {
    display: "block",
    margin: theme.spacing(-2, 0, 4, 0),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  cuisineChip: {
    margin: 2,
  },
  listbox: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
  uploadZone: {
    backgroundColor: theme.palette.divider,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: `1px dashed ${theme.palette.text.primary}`,
    marginTop: theme.spacing(2),
  },
  uploadZoneActive: {
    backgroundColor: theme.palette.success.main,
  },
  disabledZone: {
    opacity: 0.1,
  },
  uploadZoneDiv: {
    textAlign: "center",
    width: "100%",
    height: "100%",
    padding: theme.spacing(2),
  },
  progressBar: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),

    height: 10,
  },
  gridList: {
    paddingTop: theme.spacing(2),
  },
  white: {
    color: theme.palette.text.primary,
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
}));

// const imgur = new ImgurClient({ clientId: IMGUR_CLIENT_ID });

const RecipeForm = ({ state, setState }) => {
  const classes = useStyles();
  const { userData } = useContext(UserDataContext);
  const { deleteRecipe } = useContext(RecipeIndexContext);
  // console.log("userData", userData);
  const uploadFileCount = useRef(0);
  const theme = useTheme();
  const [nextIngredient, setNextIngredient] = useState({});
  const [editSectionPos, setEditSectionPos] = useState([false, false]);
  // const [currentTag, setCurrentTag] = useState("");
  const [openNewSection, setOpenNewSection] = useState(false);
  const [dietaries, setDietaries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [currentContent, setCurrentContent] = useState({});
  const [uploadProgress, setUploadProgress] = useState({});
  const [feedbackMsg, setMessage] = useState([false, false, false]);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [confirmReset, setConfirmReset] = useState(false);
  const [showTagHelp, setShowTagHelp] = useState(false);
  // console.log("recipe form state", state);
  const history = useHistory();

  const isUploading = Object.values(uploadProgress).length > 0;

  const isLarge = useMediaQuery(theme.breakpoints.up("md"));

  const resetForm = () => {
    // Reset the "new" local storage
    const currentStore = ls(LS_FORM_KEY) || {};
    ls(LS_FORM_KEY, {
      ...currentStore,
      new: false,
    });
    setState(BLANK_RECIPE);
  };

  const uploadProgressBar = isUploading && (
    <LinearProgress
      variant={
        Object.values(uploadProgress).length === uploadFileCount.current
          ? "determinate"
          : "indeterminate"
      }
      value={
        (Object.values(uploadProgress)
          .map((a) => a.loaded)
          .reduce((a, b) => a + b, 0) /
          Object.values(uploadProgress)
            .map((a) => a.total)
            .reduce((a, b) => a + b, 0)) *
        100
      }
      color="secondary"
      className={classes.progressBar}
    />
  );
  // const [cuisineList, setCuisineList] = useState([]);
  // console.log("recipe state", state);
  // console.log("ls(LS_FORM_KEY)", ls(LS_FORM_KEY));

  const onDrop = (acceptedFiles, append) => {
    uploadFileCount.current += acceptedFiles.length;
    // console.log(acceptedFiles);
    try {
      // const uploader = new ImgurAnonymousUploader(IMGUR_CLIENT_ID);

      // acceptedFiles.forEach(async (file) => {
      //   // const uploadResponse = await uploader.upload(URL.createObjectURL(file));
      //   console.log(uploadResponse);
      // });
      const uploadedLinks = [];
      acceptedFiles.forEach((file, fileIndex) => {
        const formdata = new FormData();
        console.log("file", file);
        const fileType = "image"; // file.type.substr(0, 5) === "video" ? "video" : "image";
        formdata.append(fileType, file); // URL.createObjectURL(file));
        console.log("formdata", formdata);

        axios
          .request({
            method: "post",
            url: `https://api.imgur.com/3/image/`,
            data: formdata,
            headers: {
              Authorization: `Client-ID ${IMGUR_CLIENT_ID}`,
              Accept: "application/json",
            },
            onUploadProgress: (p) => {
              // console.log(p);
              setUploadProgress((up) => ({
                ...up,
                [fileIndex]: {
                  timeStamp: p.timeStamp,
                  loaded: p.loaded,
                  total: p.total,
                },
              }));
              //this.setState({
              //fileprogress: p.loaded / p.total
              //})
            },
          })
          // .then((data) => data.json())
          .then((data) => {
            console.log("DATA", data);
            // console.log("data", (state.photos || []).length + fileIndex, data);
            if (data.data.success && data.data.data.link) {
              uploadedLinks.push(data.data.data.link);
              append(uploadedLinks);
            } else {
              uploadedLinks.push(false);
            }

            if (uploadedLinks.length === acceptedFiles.length) {
              // append(uploadedLinks);
              setUploadProgress({});
              uploadFileCount.current -= acceptedFiles.length;
            }
          })
          .catch((error) => {
            console.log(JSON.stringify(error));
            setMessage([true, error.message, "error"]);
            uploadedLinks.push(false);
            if (uploadedLinks.length === acceptedFiles.length) {
              setUploadProgress({});
              uploadFileCount.current -= acceptedFiles.length;
            }
          });
        // fetch("https://api.imgur.com/3/image/", {
        //   method: "post",
        //   headers: {
        //     Authorization: `Client-ID ${IMGUR_CLIENT_ID}`,
        //     Accept: "application/json",
        //   },
        //   body: formdata,
        // })
        //   .then((data) => data.json())
        //   .then((data) => {
        //     // console.log("data", (state.photos || []).length + fileIndex, data);
        //     if (data.success && data.data.link) {
        //       uploadedLinks.push(data.data.link);
        //       if (uploadedLinks.length === acceptedFiles.length) {
        //         append(uploadedLinks);
        //       }
        //     }
        //   });
      });
      /*
        {
          success: true,
          status: 200,
          url: 'uploaded imgur url',
          deleteHash: 'delete hash'
        }
      */

      // const deleteResponse = await uploader.delete('delete hash is here');
      // console.log(deleteResponse);
      // { success: true, status: 200 }
    } catch (error) {
      console.error(error);
    }
    // const responses = await imgur.upload([
    //   '/home/kai/dank-meme.jpg',
    //   '/home/kai/another-dank-meme',
    // ]);
    // responses.forEach((r) => console.log(r.link));
  };
  const filterOptions = createFilterOptions({
    matchFrom: "start",
    // stringify: option => option.title,
  });
  const fetchTags = async () => {
    const res = await executeQuery({
      query: {
        tags: {
          name: true,
        },
      },
    });
    // console.log(
    //   "fetchTags",
    //   res.tags.map((t) => t.name)
    // );
    setAllTags(res.tags.map((t) => t.name));
  };
  useEffect(() => {
    fetchTags();
  }, []);
  const fetchDietaries = async () => {
    const res = await executeQuery({
      query: {
        dietaries: {
          _id: true,
          name: true,
        },
      },
    });
    // console.log("fetchDietaries", res);
    setDietaries(res.dietaries);
  };
  useEffect(() => {
    fetchDietaries();
  }, []);
  const fetchAuthors = async () => {
    const res = await executeQuery({
      query: {
        users: {
          _id: true,
          name: true,
        },
      },
    });
    // console.log("fetchAuthors", res);
    setAuthors(res.users);
  };
  useEffect(() => {
    fetchAuthors();
  }, []);

  // console.log("dietaries", dietaries);

  useEffect(() => {
    const fetchCategories = async () => {
      const res = await executeQuery({
        query: {
          categories: {
            _id: true,
            name: true,
          },
        },
      });
      // console.log("fetchCategories", res);
      setCategories(res.categories);
    };
    fetchCategories();
  }, []);

  const handleSectionDelete = (sectionPos) =>
    setState((s) => ({
      ...s,
      sections: [
        ...s.sections.slice(0, sectionPos),
        ...s.sections.slice(sectionPos + 1),
      ],
    }));
  const addSection = (title) => {
    setState((s) => ({
      ...s,
      sections: [...s.sections, { title, ingredients: [], photos: [] }],
    }));
  };
  const updateSection = (sectionPos, title) => {
    setState((s) => ({
      ...s,
      sections: [
        ...s.sections.slice(0, sectionPos),
        {
          ...s.sections[sectionPos],
          title,
        },
        ...s.sections.slice(sectionPos + 1),
      ],
    }));
  };
  // const addPhoto = () => {
  //   setState((s) => ({
  //     ...s,
  //     photos: [...(s.photos || []), ""],
  //   }));
  // };
  const removeDoubleEmpty = (a) => {
    if (a.filter((b) => b.trim().length > 0).length === 0) return [];
    // const a = aa.map((b) => b.trim());
    return a.filter(
      (b, x) =>
        x === 0 ||
        x === a.length - 1 ||
        !(b.length === 0 && (x === a.length - 1 || a[x - 1].length === 0))
    );
  };
  // const setPhoto = (url, index) => {
  //   // console.log("setPhoto", url, index);
  //   setState((s) => ({
  //     ...s,
  //     photos: removeDoubleEmpty([
  //       ...(s.photos || []).slice(0, index),
  //       url,
  //       ...(s.photos || []).slice(index + 1),
  //     ]),
  //   }));
  // };
  const removePhoto = (index) => {
    // console.log("setPhoto", url, index);
    setState((s) => ({
      ...s,
      photos: [
        ...(s.photos || []).slice(0, index),
        ...(s.photos || []).slice(index + 1),
      ],
    }));
  };
  const appendPhotos = (urls) => {
    setState((s) => ({
      ...s,
      photos: [...(s.photos || []), ...urls],
    }));
  };
  const updateIngredient = (sectionPos, ingredientPos, v = false) =>
    setState((s) => ({
      ...s,
      sections: [
        ...s.sections.slice(0, sectionPos),
        {
          ...s.sections[sectionPos],
          ingredients: [
            ...(s.sections[sectionPos].ingredients || []).slice(
              0,
              ingredientPos
            ),
            ...(v === false ? [] : v.split("\n")),
            ...(s.sections[sectionPos].ingredients || []).slice(
              ingredientPos + 1
            ),
          ],
        },
        ...s.sections.slice(sectionPos + 1),
      ],
    }));
  const updateDirections = (sectionPos, method) =>
    setState((s) => ({
      ...s,
      sections: [
        ...s.sections.slice(0, sectionPos),
        {
          ...s.sections[sectionPos],
          method: JSON.stringify(method),
        },
        ...s.sections.slice(sectionPos + 1),
      ],
    }));
  const updateIntro = (sectionPos, intro) =>
    setState((s) => ({
      ...s,
      sections: [
        ...s.sections.slice(0, sectionPos),
        {
          ...s.sections[sectionPos],
          intro,
        },
        ...s.sections.slice(sectionPos + 1),
      ],
    }));
  const updateNotes = (sectionPos, notes) =>
    setState((s) => ({
      ...s,
      sections: [
        ...s.sections.slice(0, sectionPos),
        {
          ...s.sections[sectionPos],
          notes,
        },
        ...s.sections.slice(sectionPos + 1),
      ],
    }));
  const updateVideo = (sectionPos, videoUrl) =>
    setState((s) => ({
      ...s,
      sections: [
        ...s.sections.slice(0, sectionPos),
        {
          ...s.sections[sectionPos],
          videoUrl,
        },
        ...s.sections.slice(sectionPos + 1),
      ],
    }));
  const appendPhotosToSection = (sectionPos, urls) =>
    setState((s) => ({
      ...s,
      sections: [
        ...((s.sections || []).slice(0, sectionPos) || []),
        {
          ...((s.sections || [])[sectionPos] || []),
          photos: [
            ...(((s.sections || [])[sectionPos] || {}).photos || []),
            ...urls,
          ],
        },
        ...((s.sections || []).slice(sectionPos + 1) || []),
      ],
    }));
  const removePhotoFromSection = (sectionPos, index) => {
    // console.log("setPhoto", url, index);
    setState((s) => ({
      ...s,
      sections: [
        ...((s.sections || []).slice(0, sectionPos) || []),
        {
          ...((s.sections || [])[sectionPos] || []),
          photos: [
            ...(((s.sections || [])[sectionPos] || {}).photos || []).slice(
              0,
              index
            ),
            ...(((s.sections || [])[sectionPos] || {}).photos || []).slice(
              index + 1
            ),
          ],
        },
        ...((s.sections || []).slice(sectionPos + 1) || []),
      ],
    }));
  };
  // const updatePhoto = (sectionPos, photoPos, photo) =>
  //   setState((s) => ({
  //     ...s,
  //     sections: [
  //       ...((s.sections || []).slice(0, sectionPos) || []),
  //       {
  //         ...((s.sections || [])[sectionPos] || []),
  //         photos: removeDoubleEmpty([
  //           ...(((s.sections || [])[sectionPos] || {}).photos || []).slice(
  //             0,
  //             photoPos
  //           ),
  //           photo,
  //           ...(((s.sections || [])[sectionPos] || {}).photos || []).slice(
  //             photoPos + 1
  //           ),
  //         ]),
  //       },
  //       ...((s.sections || []).slice(sectionPos + 1) || []),
  //     ],
  //   }));
  const updateFieldValue = (field, value) =>
    setState((s) => ({
      ...s,
      [field]: value,
    }));
  const updateField = (field, format = (a) => a) => (event) => {
    console.log(
      "updateField",
      field,
      event.target.value,
      format(event.target.value)
    );
    return updateFieldValue(field, format(event.target.value));
  };

  const addTag = (tag) => {
    setState((s) => ({
      ...s,
      tags: [...(s.tags || []), tag],
    }));
  };
  // const addTags = (tags) => {
  //   setState((s) => ({
  //     ...s,
  //     tags: uniqueArray([...(s.tags || []), ...tags]),
  //   }));
  // };
  const addCuisines = (tags) => {
    setState((s) => ({
      ...s,
      tags: uniqueArray([
        ...(s.tags || []).filter((t) => CUISINES.indexOf(t) < 0),
        ...tags,
      ]),
    }));
  };
  const addCourse = (tags) => {
    setState((s) => ({
      ...s,
      tags: uniqueArray([
        ...(s.tags || []).filter((t) => COURSES.indexOf(t) < 0),
        ...tags,
      ]),
    }));
  };
  const removeTag = (tagPos) => {
    console.log("removeTag", tagPos, state.tags);
    setState((s) => ({
      ...s,
      tags: [
        ...(s.tags || []).slice(0, tagPos),
        ...(s.tags || []).slice(tagPos + 1),
      ],
    }));
  };
  const updateDietary = (tag, checked) => {
    // console.log("updateDietary", tag, checked, dietaries);
    let alsoTag1 = false;
    let alsoTag2 = false;
    if (
      checked &&
      dietaries.filter((d) => d._id === tag && d.name.indexOf("Vegan") >= 0)
        .length === 1
    ) {
      const veg = dietaries.filter((d) => d.name.indexOf("Vegetar") >= 0);
      const dairy = dietaries.filter((d) => d.name.indexOf("Dairy") >= 0);
      if (veg.length === 1) {
        alsoTag1 = veg[0]._id;
      }
      if (dairy.length === 1) {
        alsoTag2 = dairy[0]._id;
      }
    }
    const getNewDietaries = (s) => [
      ...(s.dietaries || []).filter(
        (d) =>
          d !== tag &&
          d !== alsoTag1 &&
          d !== alsoTag2 &&
          dietaries.some((diet) => diet._id === d)
      ),
      ...(checked ? [tag] : []),
      ...(checked && alsoTag1 ? [alsoTag1] : []),
      ...(checked && alsoTag2 ? [alsoTag2] : []),
    ];
    setState((s) => ({
      ...s,
      dietaries: getNewDietaries(s),
      dietaryNames: getNewDietaries(s).map((id) => getNameById(dietaries, id)),
    }));
  };

  const loadTestData = () => {
    if (state.title) return;
    setState(getPizzaData(categories));
  };

  const formatRte = (raw) => {
    const content = convertFromRaw(raw);
    const o = EditorState.createWithContent(content);
    return JSON.stringify(convertToRaw(o.getCurrentContent()));
  };
  const getCuisineStyles = (cuisine) => ({
    fontWeight:
      (state.tags || []).indexOf(cuisine) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  });

  // const handleCuisineChange = (event) => {
  //   setCuisineList(event.target.value);
  // };

  // const handleCuisineMultiple = (event) => {
  //   const { options } = event.target;
  //   const value = [];
  //   for (let i = 0, l = options.length; i < l; i += 1) {
  //     if (options[i].selected) {
  //       value.push(options[i].value);
  //     }
  //   }
  //   setCuisineList(value);
  // };
  const videoUrlError =
    (state.videoUrl || "").length > 0 && !isUrl(state.videoUrl || "");
  // console.log("state", state);
  return (
    <div className={classes.container}>
      <section>
        <Typography
          variant="h3"
          className={clsx(classes.heading, classes.headingMargin)}
        >
          {state._id ? "Edit Recipe" : "New Recipe"}
          <div className={classes.flexEnd}>
            {!state._id && (
              <IconButton onClick={loadTestData}>
                <LoadIcon className={classes.loadIcon} />
              </IconButton>
            )}
            <Button
              color="secondary"
              variant="outlined"
              size="small"
              onClick={() => setOpenDeleteConfirmation(true)}
              className={classes.marginRight}
            >
              Delete
            </Button>
            <Button
              color="secondary"
              variant="outlined"
              size="small"
              onClick={() => {
                if (!state._id) {
                  setConfirmReset(true);
                } else {
                  resetForm();
                }
              }}
              // className={classes.headingButton}
            >
              Reset
            </Button>
          </div>
        </Typography>
        {state.slug && (
          <Link
            // variant="caption"
            className={classes.recipeLink}
            href={`${WEBSITE_URL}/recipe/${state.slug}`}
          >{`${WEBSITE_URL}/recipe/${state.slug}`}</Link>
        )}

        <FormControl className={classes.formControl}>
          <TextField
            label="Recipe Title"
            helperText="e.g. Pizza Margherita"
            variant="outlined"
            fullWidth
            size="small"
            className={classes.textField}
            onChange={updateField("title")}
            value={state.title || ""}
            required
          />
        </FormControl>

        <FormControl className={classes.formControl}>
          <TextField
            label="Recipe Subtitle"
            helperText="e.g. Neapolitan pizza with tomatoes, mozzarella &amp; basil."
            variant="outlined"
            fullWidth
            size="small"
            className={classes.textField}
            onChange={updateField("subtitle")}
            value={state.subtitle || ""}
          />
        </FormControl>

        <FormControl
          className={classes.formControl}
          variant="outlined"
          size="small"
          required
        >
          <InputLabel>Primary Category</InputLabel>
          {categories.length > 0 && (
            <Select
              // value={age}
              // onChange={handleChange}
              fullWidth
              label="Primary Category"
              // onChange={updateField("category")}
              onChange={(event) => {
                setState((s) => ({
                  ...s,
                  category: event.target.value,
                  categoryName: getNameById(categories, event.target.value),
                }));
              }}
              value={state.category || ""}
            >
              {categories.map((category) => (
                <MenuItem key={category._id} value={category._id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          )}
          <FormHelperText className={classes.italic}>
            The category that best fits. Any others can be added as tags.
          </FormHelperText>
        </FormControl>

        <FormControl
          className={classes.formControl}
          variant="outlined"
          size="small"
        >
          <InputLabel>Cuisine</InputLabel>
          <Select
            fullWidth
            label="Cuisine"
            multiple
            value={(state.tags || []).filter((t) => CUISINES.indexOf(t) >= 0)}
            onChange={(event) => addCuisines(event.target.value)}
            // input={<Input id="select-multiple-chip" />}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={value}
                    className={classes.cuisineChip}
                    color="secondary"
                  />
                ))}
              </div>
            )}
            // MenuProps={MenuProps}
          >
            {CUISINES.map((cuisine) => (
              <MenuItem
                key={cuisine}
                value={cuisine}
                style={getCuisineStyles(cuisine)}
              >
                <Checkbox checked={(state.tags || []).indexOf(cuisine) > -1} />
                <ListItemText primary={cuisine} />
              </MenuItem>
            ))}
          </Select>
          <FormHelperText className={classes.italic}>
            The cuisine(s) that best fits. Any others can be added as tags.
          </FormHelperText>
        </FormControl>
        <FormControl
          className={classes.formControl}
          variant="outlined"
          size="small"
        >
          <InputLabel>Meal Course</InputLabel>
          <Select
            fullWidth
            label="Meal Course"
            multiple
            value={(state.tags || []).filter((t) => COURSES.indexOf(t) >= 0)}
            onChange={(event) => addCourse(event.target.value)}
            // input={<Input id="select-multiple-chip" />}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={value}
                    className={classes.cuisineChip}
                    color="secondary"
                  />
                ))}
              </div>
            )}
            // MenuProps={MenuProps}
          >
            {COURSES.map((course) => (
              <MenuItem
                key={course}
                value={course}
                style={getCuisineStyles(course)}
              >
                <Checkbox checked={(state.tags || []).indexOf(course) > -1} />
                <ListItemText primary={course} />
              </MenuItem>
            ))}
          </Select>
          <FormHelperText className={classes.italic}>
            The course(s) that best fits. Any others can be added as tags.
          </FormHelperText>
        </FormControl>
        <FormControl
          className={classes.formControl}
          variant="outlined"
          size="small"
          required
        >
          <InputLabel>Author</InputLabel>
          <Select
            // value={age}
            // onChange={handleChange}
            fullWidth
            label="Author"
            onChange={(event) => {
              // updateField("author");
              console.log(
                "AUTHOR2",
                state.authorId,
                event.target.value,
                state.authorName,
                getNameById(authors, event.target.value)
              );
              setState((s) => ({
                ...s,
                authorId: event.target.value,
                authorName: getNameById(authors, event.target.value),
              }));
            }}
            value={state.authorId || userData._id}
          >
            {authors.map((author) => (
              <MenuItem value={author._id} key={author._id}>
                {author.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText className={classes.italic}>
            Whose recipe is it?
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formControl}>
          <TextField
            label="Servings"
            helperText="e.g. 4-6 people"
            variant="outlined"
            fullWidth
            size="small"
            className={classes.textField}
            onChange={updateField("size")}
            value={state.size || ""}
          />
        </FormControl>

        <FormControl className={clsx(classes.formControl, classes.formRow)}>
          <FormControl className={classes.formCol}>
            <FormControl className={classes.formRow}>
              <TextField
                label="Hours"
                // helperText="Total active time, approximately (cook + prep)"
                variant="outlined"
                fullWidth
                size="small"
                className={classes.textField}
                onChange={updateField(
                  "activeTime",
                  (hrs) =>
                    (parseFloat(hrs) || 0) * 60 + ((state.activeTime || 0) % 60)
                )}
                // onChange={(event) => {
                //   const time =
                //   updateFieldValue
                // }}
                type="number"
                // placeholder="0"
                value={Math.floor((state.activeTime || 0) / 60) || ""}
              />
              <TextField
                label="Minutes"
                // helperText="Total active time, approximately (cook + prep)"
                variant="outlined"
                fullWidth
                size="small"
                className={classes.textField}
                onChange={updateField(
                  "activeTime",
                  (mins) =>
                    Math.floor((state.activeTime || 0) / 60) * 60 +
                    (parseFloat(mins) || 0)
                )}
                type="number"
                // placeholder="0"
                value={(state.activeTime || 0) % 60 || ""}
              />
            </FormControl>
            <FormHelperText>Total active time (cook + prep)</FormHelperText>
          </FormControl>
          <FormControl className={clsx(classes.formCol, classes.flexGrow)}>
            <TextField
              label="Inactive time"
              variant="outlined"
              fullWidth
              size="small"
              className={classes.textField}
              onChange={updateField("inactiveTime")}
              value={state.inactiveTime || ""}
            />
            <FormHelperText>e.g. "Plus Proving Time".</FormHelperText>
          </FormControl>
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            label="Required Equipment"
            helperText="Any special equipment needed for the recipe"
            variant="outlined"
            fullWidth
            size="small"
            className={classes.textField}
            onChange={updateField("equipment", (a) => {
              const l = (a || "").split("\n");
              console.log("update equip", a, typeof a, JSON.stringify(a), l);
              // .map((b) => b.trim())
              // return l.filter((b, x) => b.length > 0 || x === l.length - 1);
              return removeDoubleEmpty(l);
            })}
            value={(state.equipment || []).join("\n")}
            multiline
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <DifficultyRating
            value={state.difficulty || 0}
            onChange={(newValue) => {
              updateFieldValue("difficulty", newValue);
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            label="Page Introduction"
            helperText="Write about the recipe's history, etc."
            variant="outlined"
            fullWidth
            size="small"
            className={classes.textField}
            multiline
            onChange={updateField("intro")}
            value={state.intro || ""}
          />
        </FormControl>
      </section>
      <section className={classes.section}>
        <Typography variant="h3" className={classes.heading}>
          The Recipe
          <Button
            color="secondary"
            variant="outlined"
            size="small"
            onClick={() => setOpenNewSection(true)}
            className={classes.headingButton}
          >
            Add New Section
          </Button>
        </Typography>
        <FormHelperText>
          You only need to add sections if your recipe is split into sections
          such as "Pastry" and "Filling".
        </FormHelperText>
      </section>
      {(state.sections || []).map((section, sectionPos) => (
        <section key={sectionPos} className={classes.section}>
          {sectionPos > 0 && <Divider className={classes.divider} />}
          {state.sections.length > 1 && (
            <Typography variant="h4" className={classes.sectionHeading}>
              {section.title || state.title || "Main Section"}
              <span className={classes.iconGroup}>
                <IconButton
                  // size="small"
                  color="primary"
                  onClick={() => setEditSectionPos([sectionPos, section.title])}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  // size="small"
                  color="primary"
                  onClick={() => handleSectionDelete(sectionPos)}
                  disabled={
                    !(
                      state.sections.length > 1 &&
                      !section.intro &&
                      !section.method &&
                      section.ingredients.length === 0
                    )
                  }
                >
                  <DeleteIcon className={classes.white} />
                </IconButton>
              </span>
            </Typography>
          )}
          {state.sections.length > 1 && (
            <FormControl className={classes.formControl}>
              <TextField
                multiline
                label="Section Introduction"
                variant="outlined"
                helperText="Optional introduction to this section"
                fullWidth
                className={classes.textField}
                size="small"
                value={section.intro || ""}
                onChange={(event) =>
                  updateIntro(sectionPos, event.target.value)
                }
              />
            </FormControl>
          )}
          <Typography variant="h5" className={classes.directionsHeading}>
            Ingredients List
          </Typography>
          {(section.ingredients || []).map((ingredient, ingredientPos) => (
            <Grid
              container
              spacing={1}
              alignItems="center"
              justify="center"
              key={ingredientPos}
              className={classes.ingredientGrid}
            >
              <Grid item>
                <IconButton>
                  <DragIcon />
                </IconButton>
              </Grid>
              <Grid item className={classes.grow}>
                <TextField
                  variant="outlined"
                  value={ingredient || ""}
                  multiline
                  placeholder="Ingredient and quantity"
                  onChange={(event) =>
                    updateIngredient(
                      sectionPos,
                      ingredientPos,
                      event.target.value
                    )
                  }
                  fullWidth
                  className={classes.ingredient}
                  size="small"
                />
              </Grid>
              <Grid item>
                <IconButton
                  onClick={() => updateIngredient(sectionPos, ingredientPos)}
                  color="primary"
                >
                  <DeleteIcon className={classes.white} />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <TextField
            variant="outlined"
            fullWidth
            multiline
            onChange={(event) =>
              setNextIngredient((n) => ({
                ...n,
                [sectionPos]: event.target.value,
              }))
            }
            onBlur={() => {
              if (nextIngredient[sectionPos]) {
                updateIngredient(
                  sectionPos,
                  (section.ingredients || []).length,
                  nextIngredient[sectionPos]
                );
                setNextIngredient((n) => ({ ...n, [sectionPos]: "" }));
              }
            }}
            onKeyDown={(e) => {
              if (
                (e.keyCode === 13 || e.keyCode === 9) &&
                nextIngredient[sectionPos]
              ) {
                e.stopPropagation();
                e.preventDefault();
                e.target.blur();
                setTimeout(() => e.target.focus(), 10);
              }
            }}
            value={nextIngredient[sectionPos] || ""}
            label="Ingredient and quantity"
            helperText="e.g. 100g plain flour"
            className={classes.ingredient}
            size="small"
          />
          <Typography variant="h5" className={classes.directionsHeading}>
            Recipe Instructions
          </Typography>
          <div className={classes.rteContainer}>
            <MUIRichTextEditor
              label="Type the recipe method"
              inlineToolbar={true}
              // test={console.log("MUIRTF", typeof section.method)}
              defaultValue={
                section.method
                  ? formatRte(
                      typeof section.method === "string"
                        ? JSON.parse(section.method)
                        : section.method
                    )
                  : undefined
              }
              onChange={(s) => setCurrentContent(s.getCurrentContent())}
              onBlur={() =>
                updateDirections(
                  sectionPos,
                  currentContent.hasText()
                    ? convertToRaw(currentContent)
                    : undefined
                )
              }
              controls={[
                "title",
                "bold",
                "italic",
                "underline",
                "numberList",
                "bulletList",
                "link",
              ]}
            />
            <FormHelperText
              className={clsx(classes.italic, classes.helperIndent)}
            >
              Write up the recipe method with optional formatting
            </FormHelperText>
          </div>
          <FormControl className={classes.formControl}>
            <TextField
              multiline
              label="Notes"
              variant="outlined"
              helperText="Tips and extra information"
              fullWidth
              className={classes.textField}
              size="small"
              value={section.notes || ""}
              onChange={(event) => updateNotes(sectionPos, event.target.value)}
            />
          </FormControl>

          <Typography variant="h5" className={classes.directionsHeading}>
            Section Media
          </Typography>
          <FormControl className={classes.formControl}>
            {/* {[...(section.photos || []), ""].map((photo, photoPos) => (
              <TextField
                key={`section${sectionPos}-${photoPos}`}
                // multiline
                label={`Section Photo Link ${photoPos + 1}`}
                variant="outlined"
                type="url"
                // helperText={
                //   (section.photos || []).length === photoPos
                //     ? "Image URL from Imgur"
                //     : undefined
                // }
                fullWidth
                className={classes.textField}
                size="small"
                value={photo || ""}
                onChange={(event) =>
                  updatePhoto(sectionPos, photoPos, event.target.value)
                }
              />
            ))} */}

            {(section.photos || []).length > 0 && (
              <GridList
                cellHeight={120}
                className={classes.gridList}
                cols={isLarge ? 6 : 3}
              >
                {(section.photos || []).map((photo, photoPos) => (
                  <GridListTile key={photoPos}>
                    <img src={photo} alt={photo} />
                    {Object.values(uploadProgress).length === 0 && (
                      <GridListTileBar
                        actionIcon={
                          <IconButton
                            className={classes.icon}
                            onClick={() =>
                              removePhotoFromSection(sectionPos, photoPos)
                            }
                          >
                            <DeleteIcon className={classes.white} />
                          </IconButton>
                        }
                      />
                    )}
                  </GridListTile>
                ))}
              </GridList>
            )}

            <Dropzone
              onDrop={(files) =>
                onDrop(files, (photos) =>
                  appendPhotosToSection(sectionPos, photos)
                )
              }
              accept="image/jpeg, image/png, image/gif" // , video/*
              disabled={isUploading}
            >
              {({ getRootProps, getInputProps, isDragActive }) => (
                <section
                  className={clsx(
                    classes.uploadZone,
                    isDragActive ? classes.uploadZoneActive : {},
                    isUploading ? classes.disabledZone : {}
                  )}
                >
                  <div {...getRootProps()} className={classes.uploadZoneDiv}>
                    <input {...getInputProps()} />
                    <p>Drag and drop image files here or click</p>
                    <UploadIcon style={{ fontSize: 40 }} />
                  </div>
                </section>
              )}
            </Dropzone>
            {uploadProgressBar}
            <FormHelperText
              className={clsx(classes.italic, classes.helperIndent)}
            >
              Add photos to help with this section.
            </FormHelperText>
            {/* <TextField
              multiline
              label={`Section Photo ${(section.photos || []).length + 1}`}
              variant="outlined"
              helperText="Photo URL from Imgur"
              fullWidth
              className={classes.textField}
              size="small"
              value=""
              onChange={(event) =>
                updatePhoto(
                  sectionPos,
                  (section.photos || []).length,
                  event.target.value
                )
              }
            /> */}
            {/* <FormHelperText>Photo URL from Imgur</FormHelperText> */}
          </FormControl>

          <TextField
            label="Section Video Link"
            variant="outlined"
            type="url"
            helperText="Add a YouTube URL if you've filmed this section"
            fullWidth
            className={classes.textField}
            size="small"
            value={section.videoUrl || ""}
            onChange={(event) => updateVideo(sectionPos, event.target.value)}
          />

          {sectionPos === (state.sections || []).length - 1 && (
            <div className={classes.flexEnd}>
              <Button
                color="secondary"
                variant="outlined"
                size="small"
                onClick={() => setOpenNewSection(true)}
                className={classes.headingButton}
              >
                Add New Section
              </Button>
            </div>
          )}
        </section>
      ))}

      <Typography variant="h3" className={classes.heading}>
        Featured Media
      </Typography>

      <FormControl className={classes.formControl}>
        <Typography variant="h5" className={classes.directionsHeading}>
          Photos
        </Typography>

        {(state.photos || []).length > 0 && (
          <GridList
            cellHeight={120}
            className={classes.gridList}
            cols={isLarge ? 6 : 3}
          >
            {(state.photos || []).map((photo, photoPos) => (
              <GridListTile key={photoPos}>
                <img src={photo} alt={photo} />
                {Object.values(uploadProgress).length === 0 && (
                  <GridListTileBar
                    actionIcon={
                      <IconButton
                        className={classes.icon}
                        onClick={() => removePhoto(photoPos)}
                      >
                        <DeleteIcon className={classes.white} />
                      </IconButton>
                    }
                  />
                )}
              </GridListTile>
            ))}
          </GridList>
        )}

        {/* {[...(state.photos || []), ""].map((photo, photoPos) => (
          <TextField
            key={`recipephoto-${photoPos}`}
            label={`Photo Link ${photoPos + 1}`}
            variant="outlined"
            // helperText={
            //   (state.photos || []).length === photoPos
            //     ? "Image URL from Imgur"
            //     : undefined
            // }
            fullWidth
            type="url"
            className={classes.textField}
            size="small"
            value={photo || ""}
            onChange={(event) => setPhoto(event.target.value, photoPos)}
          />
        ))} */}
        <Dropzone
          onDrop={(files) => onDrop(files, appendPhotos)}
          accept="image/jpeg, image/png, image/gif" // , video/*
          disabled={isUploading}
        >
          {({ getRootProps, getInputProps, isDragActive }) => (
            <section
              className={clsx(
                classes.uploadZone,
                isDragActive ? classes.uploadZoneActive : {},
                isUploading ? classes.disabledZone : {}
              )}
            >
              <div {...getRootProps()} className={classes.uploadZoneDiv}>
                <input {...getInputProps()} />
                <p>Drag and drop image files here or click</p>
                <UploadIcon style={{ fontSize: 40 }} />
              </div>
            </section>
          )}
        </Dropzone>
        {uploadProgressBar}
        <FormHelperText className={clsx(classes.italic, classes.helperIndent)}>
          Add photos to show the finished item or to help with the recipe
          method.
        </FormHelperText>
      </FormControl>
      <FormControl className={classes.formControl}>
        <TextField
          label="Video Link"
          helperText={
            videoUrlError
              ? "Invalid URL"
              : "Add a YouTube URL if you've filmed the recipe being made"
          }
          variant="outlined"
          type="url"
          fullWidth
          size="small"
          className={classes.textField}
          onChange={updateField("videoUrl")}
          value={state.videoUrl || ""}
          error={videoUrlError}
        />
      </FormControl>

      <Typography variant="h3" className={classes.heading}>
        Tags
      </Typography>
      <FormGroup row className={classes.formControl}>
        {dietaries.map((d) => (
          <FormControlLabel
            key={d._id}
            control={
              <Checkbox
                color="primary"
                checked={(state.dietaries || []).indexOf(d._id) >= 0}
                onChange={(event) => updateDietary(d._id, event.target.checked)}
              />
            }
            label={d.name}
            className={classes.checkboxLabel}
          />
        ))}
        <FormHelperText className={clsx(classes.italic, classes.helperIndent)}>
          Tick any of the dietary categories that apply to this recipe
        </FormHelperText>
      </FormGroup>
      {/* <FormControl className={classes.formControl}>
        <TextField
          label="Add New Tag"
          placeholder="Start typing tag..."
          helperText="Add tags for the cuisine type, food categories, etc"
          variant="outlined"
          fullWidth
          size="small"
          className={classes.textField}
          value={currentTag || ""}
          onChange={(event) => setCurrentTag(event.target.value)}
          onKeyDown={(e) => {
            if (
              (e.keyCode === 9 || e.keyCode === 13 || e.keyCode === 188) &&
              currentTag
            ) {
              e.preventDefault();
              setTimeout(() => setCurrentTag(""), 0);
              setTimeout(() => addTag(currentTag), 10);
            }
          }}
        />
      </FormControl> */}
      <FormControl className={classes.formControl}>
        <Autocomplete
          // id="combo-box-demo"
          options={allTags.map((title) => ({ title }))}
          // getOptionLabel={(option) => option.name}
          // style={{ width: 300 }}
          renderTags={() => false}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          onChange={(event, newValue) => {
            // if (typeof newValue === "string") {
            //   console.log("AUTO1", {
            //     title: newValue,
            //   });
            // } else if (newValue && newValue.inputValue) {
            //   // Create a new value from the user input
            //   console.log("AUTO2", {
            //     title: newValue.inputValue,
            //   });
            // } else {
            //   console.log("AUTO3", newValue);
            // }
            const newTags = newValue
              .map((v) => v.inputValue || v.title || v)
              .filter((t) => (state.tags || []).indexOf(t) < 0);
            console.log("newTags", newTags);
            addTag(newTags[newTags.length - 1]);
          }}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === "string") {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.title;
          }}
          freeSolo
          multiple
          filterOptions={(options, s) => {
            const filtered =
              s.inputValue.length > 0 ? filterOptions(options, s) : [];

            // Suggest the creation of a new value
            if (s.inputValue !== "") {
              filtered.push({
                inputValue: s.inputValue,
                title: `Add "${s.inputValue}"`,
              });
            }
            return filtered;
          }}
          renderOption={(option) => option.title}
          forcePopupIcon={false}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Add tags"
              variant="outlined"
              size="small"
            />
          )}
        />
        <FormHelperText>
          Add tags for the cuisine type, food categories, etc.{" "}
          <Link onClick={() => setShowTagHelp(true)}>What are tags?</Link>
        </FormHelperText>
      </FormControl>
      {(state.tags || []).map((tag, tagPos) => (
        <Chip
          key={tag}
          label={tag}
          onDelete={() => removeTag(tagPos)}
          className={classes.chip}
          color="secondary"
        />
      ))}

      <Snackbar
        open={feedbackMsg && feedbackMsg[0]}
        autoHideDuration={6000}
        onClose={() => setMessage((m) => [false, m[1], m[2]])}
        className={classes.snackbar}
      >
        <Alert
          onClose={() => setMessage((m) => [false, m[1], m[2]])}
          severity={feedbackMsg[2]}
        >
          {feedbackMsg[1]}
        </Alert>
      </Snackbar>
      <Dialog
        open={editSectionPos[0] !== false}
        onClose={() => setEditSectionPos([false, false])}
      >
        <DialogTitle>Edit Section Title</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Section Name"
            fullWidth
            value={
              typeof editSectionPos[1] === "string"
                ? editSectionPos[1] || ""
                : ""
            }
            onChange={(event) =>
              setEditSectionPos((s) => [s[0], event.target.value])
            }
            onKeyDown={(e) => {
              if (
                e.keyCode === 13 &&
                typeof editSectionPos[1] === "string" &&
                editSectionPos[1].length > 0
              ) {
                setTimeout(() => setEditSectionPos([false, false]), 0);
                setTimeout(
                  () => updateSection(editSectionPos[0], editSectionPos[1]),
                  10
                );
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setEditSectionPos([false, false])}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setTimeout(() => setEditSectionPos([false, false]), 0);
              setTimeout(
                () => updateSection(editSectionPos[0], editSectionPos[1]),
                10
              );
            }}
            color="secondary"
            disabled={
              !(
                typeof editSectionPos[1] === "string" &&
                editSectionPos[1].length > 0
              )
            }
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openNewSection !== false}
        onClose={() => setOpenNewSection(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">New Section</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add a new section for your recipe. Each section has ingredients and
            directions.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Section Name"
            fullWidth
            value={
              typeof openNewSection === "string" ? openNewSection || "" : ""
            }
            onChange={(event) => setOpenNewSection(event.target.value)}
            onKeyDown={(e) => {
              if (
                e.keyCode === 13 &&
                typeof openNewSection === "string" &&
                openNewSection.length > 0
              ) {
                setTimeout(() => setOpenNewSection(false), 0);
                setTimeout(() => addSection(openNewSection), 10);
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenNewSection(false)} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              setTimeout(() => setOpenNewSection(false), 0);
              setTimeout(() => addSection(openNewSection), 10);
            }}
            color="secondary"
            disabled={
              !(typeof openNewSection === "string" && openNewSection.length > 0)
            }
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDeleteConfirmation !== false}
        onClose={() => setOpenDeleteConfirmation(false)}
      >
        <DialogTitle>Delete Recipe?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you absolutely sure you want to delete this recipe and all of
            its contents?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDeleteConfirmation(false)}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              deleteRecipe(
                state._id,
                () => {
                  setOpenDeleteConfirmation(false);
                  history.push("/");
                },
                console.log
              );
            }}
            color="secondary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showTagHelp} onClose={() => setShowTagHelp(false)}>
        <DialogTitle>What are tags?</DialogTitle>
        <DialogContent>
          <DialogContentText component="div">
            <Typography>
              Tags are a simple way to organise and categorise recipes. They are
              especially useful when searching for recipes, so it's a good idea
              to add a tag for things you think people might search for. Add as
              many tags as you like.
            </Typography>
            <Typography>
              Common examples are the cuisine/country ("Thai"), course type
              ("starter"), style of cooking ("steamed") and any health-related
              aspects that aren't in the dietary options.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowTagHelp(false)} color="secondary">
            Done
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmReset} onClose={() => setConfirmReset(false)}>
        <DialogTitle>Reset form?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will wipe everything in this form. Are you sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmReset(false)} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              resetForm();
              setConfirmReset(false);
            }}
            color="secondary"
          >
            Reset
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
RecipeForm.propTypes = {
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  // switchToNewRecipe: PropTypes.func.isRequired,
};

export default RecipeForm;
