import React, { useEffect, useState, useMemo, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
// import YouTube from "react-youtube";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import isUrl from "is-url";
import "react-photoswipe/lib/photoswipe.css";
import { PhotoSwipe } from "react-photoswipe";
import imageSize from "image-size";
import url from "url";
import http from "http";
import { Link } from "react-router-dom";
import moment from "moment";
// import α from "color-alpha";

// import ls from "local-storage";

import PeopleIcon from "@material-ui/icons/People";
import ServingIcon from "@material-ui/icons/Restaurant";
import TimeIcon from "@material-ui/icons/Timer";
import Divider from "@material-ui/core/Divider";
import AuthorIcon from "@material-ui/icons/LocalLibraryOutlined";
import CategoryIcon from "@material-ui/icons/LabelOutlined";
import DietIcon from "@material-ui/icons/LocalDiningOutlined";
import Star from "@material-ui/icons/Star";
import StarBorder from "@material-ui/icons/StarBorder";
import Chip from "@material-ui/core/Chip";
import Checkbox from "@material-ui/core/Checkbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
// import { convertToRaw } from "draft-js";
// import MUIRichTextEditor from "mui-rte";
import draftToHtml from "draftjs-to-html";

import DifficultyRating from "./DifficultyRating";
import { uniqueArray, embedYoutube } from "./helperFunctions";
import { formatIngredient } from "./ingredients";

import { UserDataContext } from "./UserDataContext";
import "./RecipeView.css";
import { formatTime } from "./helperFunctions";
// import { LS_FORM_KEY } from "./constants";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",

    maxWidth: 870,
    padding: "40px 20px 20px 20px",
    backgroundSize: "110%",
    [theme.breakpoints.up("md")]: {
      maxWidth: 1150,
      padding: "50px 140px 20px 140px",
      backgroundSize: "100%",
    },
    margin: "0px auto",
    // backgroundImage:
    //   theme.palette.type === "light"
    //     ? "url(/background-light.jpg)"
    //     : "url(/background-dark.jpg)",
    backgroundPosition: "center",
    backgroundRepeatY: "repeat",
    minHeight: "100vh",
    // marginTop: -8,
    // marginTop: theme.spacing(4),
  },
  title: {
    // borderBottom: `2px solid ${theme.palette.primary.main}`,
    lineHeight: "56px",
    width: "100%",
    textAlign: "center",
    paddingTop: theme.spacing(5),
    // maxWidth: 870,
    // margin: "0px auto",
    // background:
    //   theme.palette.type === "dark"
    //     ? "linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.4) 50%, rgba(0,0,0,0) 100%)"
    //     : "linear-gradient(0deg, rgba(255,255,255,0.7) 0%, rgba(255,255,255,0.4) 50%, rgba(255,255,255,0) 100%)",
  },
  subtitle: {
    margin: theme.spacing(1, 0, 3, 1),
    paddingTop: theme.spacing(1),
    textAlign: "center",
  },
  author: {
    fontSize: "70%",
  },
  sectionRow: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
    },
  },
  divider: {
    margin: theme.spacing(2, 0),
    height: 2,
    backgroundColor: theme.palette.primary.main,
  },
  ingredientsList: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    // padding: theme.spacing(0, 2),
    // border: `1px dashed ${theme.palette.text.primary}`,
    // borderRadius: theme.spacing(1),
    fontStyle: "italic",
    minWidth: 150,

    [theme.breakpoints.up("sm")]: {
      width: "33.3%",
      marginRight: theme.spacing(2),
    },
  },
  listItemIcon: {
    minWidth: "auto",
  },
  // ingredientItem: {
  //   padding: theme.spacing(0),
  // },
  sectionContent: {
    marginTop: 0, // theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontSize: "1rem",
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.spacing(4),
      width: "66.7%",
    },
  },
  intro: {
    marginBottom: theme.spacing(1),
    fontSize: "1.3rem",
  },
  icon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  // imageBg: {
  //   width: "calc(100% + 32px)",
  //   backgroundPosition: "center",
  //   backgroundRepeat: "no-repeat",
  //   backgroundSize: "cover",
  //   height: 200,
  //   borderBottom: `2px solid ${theme.palette.primary.main}`,
  //   margin: theme.spacing(0, -2),
  //   display: "flex",
  //   justifyContent: "center",
  //   alignItems: "flex-end",
  // },
  imageContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  imageMain: {
    width: "100%",
    // maxWidth: 650,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "60vmin",
    maxHeight: 500,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    // margin: "0px auto",
    marginBottom: theme.spacing(2),
    cursor: "pointer",
  },
  imageThumb: {
    width: `calc(50% - ${theme.spacing(1)}px)`,
    height: "35vmin",
    marginBottom: theme.spacing(2),
  },
  infoItems: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  infoItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: theme.spacing(0, 2),
    fontFamily: ['"Montserrat"', "sans-serif"],
    fontWeight: 400,
    textTransform: "uppercase",
  },
  sectionNote: {
    // fontStyle: "italic",
    // fontSize: "0.875rem",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  // sectionPhoto: {
  //   marginBottom: theme.spacing(4),
  // },
  galleryThumb: {
    // width: "50%",
    maxWidth: 300,
    maxHeight: 150,
    margin: theme.spacing(0, 2, 0, 0),
    cursor: "pointer",
  },
  sectionHeader: {
    fontStyle: "italic",
    marginLeft: 0,
    marginTop: theme.spacing(2),
  },
  ingredientsHeader: {
    fontStyle: "italic",
    marginLeft: 0,
  },
  equip: {
    marginBottom: theme.spacing(2),
  },
  equipList: {
    marginBottom: theme.spacing(5),
  },
  video: {
    margin: theme.spacing(1, 0),
  },
  chip: {
    margin: theme.spacing(0, 1, 1, 0),
  },
  ingredientsListItem: {
    alignItems: "flex-start",
    marginLeft: theme.spacing(-2),
    width: `calc(100% + ${theme.spacing(2)}px)`,
  },
  ingredientsText: {
    marginTop: theme.spacing(1.4),
    // paddingRight: theme.spacing(2),
  },
  sectionTitle: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  ingredientSize: {
    marginLeft: theme.spacing(2),
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginRight: theme.spacing(2),
  },
  avatarSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    whiteSpace: "nowrap",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginBottom: 0,
      // borderRight: `1px solid ${α(theme.palette.primary.main, 0.5)}`,
      // paddingRight: 20,
    },
  },
  infoSection: {
    display: "flex",
    margin: theme.spacing(3, 0),
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  editButton: {
    marginLeft: theme.spacing(2),
  },
  gold: {
    color: "gold",
  },
}));

const RecipeView = ({ recipe, loading, editMode }) => {
  const classes = useStyles();
  const [photoDimensions, setPhotoDimensions] = useState({});
  const [openGallery, setOpenGallery] = useState(false);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [ingredientSize, setIngredientSize] = useState(1);
  const [ingredientUnit, setIngredientUnit] = useState("metric");

  const { userData, favouriteRecipe, unfavouriteRecipe } =
    useContext(UserDataContext);

  const fav =
    userData.favourites.map((rec) => rec._id).indexOf(recipe._id) !== -1;
  // const history = useHistory();

  // console.log("recipe", recipe);
  const ytUrlToId = (url) => {
    if (!isUrl(url)) return false;
    const re =
      /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/i;
    const m = re.exec(url);
    if (m && Array.isArray(m) && m.length > 1 && m[1]) return m[1];
    return false;
  };

  const formatPhoto = (img, size = "m") => {
    // https://i.imgur.com/qqBOwwa.jpg
    /*
    s =   90×  90 = Small Square (as seen in the example above)
    b =  160× 160 = Big Square 
    t =  160× 160 = Small Thumbnail 
    m =  320× 320 = Medium Thumbnail 
    l =  640× 640 = Large Thumbnail
    h = 1024×1024 = Huge Thumbnail
    */
    if (size && img.indexOf("imgur.com") >= 0 && img.substr(-4) === ".jpg") {
      return `${img.substr(0, img.length - 4)}${size}${img.substr(-4)}`;
    }
    return img;
  };
  const getMethod = (m) => (typeof m === "string" ? JSON.parse(m) : m);
  const methodHasHeader = (a) => {
    const m = getMethod(a);
    return m && m.blocks && m.blocks[0]?.type.substr(0, 6) === "header";
  };

  // console.log("recipe view", recipe);
  // const validRecipe = true;
  //   if (
  //     recipe.sections[0].method &&
  //     recipe.sections[0].method.getCurrentContent().hasText()
  //   ) {
  //     console.log(
  //       "raw",
  //       convertToRaw(recipe.sections[0].method.getCurrentContent())
  //     );
  //   }

  const validPhotos = useMemo(
    () => (recipe.photos || []).filter((p) => p.trim()),
    [recipe]
  );
  const allPhotos = useMemo(
    () =>
      uniqueArray([
        ...validPhotos,
        ...(recipe.sections || []).map((s) => s.photos || []).flat(),
      ]).filter((p) => isUrl(p)),
    [validPhotos, recipe]
  );
  // console.log("allPhotos", allPhotos);
  useEffect(() => {
    allPhotos
      .filter((p) => p && typeof p === "string" && p.trim())
      .filter((p) => Object.keys(photoDimensions).indexOf(p) < 0)
      // .slice(0, 1)
      .forEach((photoUrl) => {
        // console.log("HTTP GET", photoUrl);
        http.get(url.parse(photoUrl), (response) => {
          // console.log("response", response);
          const chunks = [];
          response
            .on("data", function (chunk) {
              // console.log("on data");
              chunks.push(chunk);
            })
            .on("end", function () {
              // console.log("on end");
              const buffer = Buffer.concat(chunks);
              const d = imageSize(buffer);
              if (!photoDimensions[photoUrl]) {
                setPhotoDimensions((pd) => ({
                  ...pd,
                  [photoUrl]: [d.width, d.height],
                }));
              }

              // imageSize(buffer, (err, dimensions) => {
              //   console.log("2", photoUrl, err, dimensions);
              // });
            });
        });
      });
  }, [allPhotos, photoDimensions]);
  // console.log("photoDimensions", photoDimensions);
  if (loading) {
    return (
      <div>
        <div className={classes.container}></div>
      </div>
    );
  }
  return (
    <div id="RecipeView">
      {/* <div
        className={classes.imageBg}
        style={
          recipe.photos && (recipe.photos || []).length > 0
            ? { backgroundImage: `url(${recipe.photos[0]})` }
            : {}
        }
      >

    </div> */}

      <div className={classes.container}>
        <Typography variant="h2" className={classes.title}>
          {recipe.title}
        </Typography>
        <Typography variant="h5" className={classes.subtitle}>
          {recipe.subtitle}
        </Typography>

        {!editMode && (
          <>
            <IconButton
              elevation={1}
              aria-label={`Bookmark ${recipe.title}`}
              className={classes.bookmarkBtn}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                fav
                  ? unfavouriteRecipe(recipe._id)
                  : favouriteRecipe(recipe._id);
              }}
              component="div"
            >
              {fav ? (
                <Star className={classes.gold} />
              ) : (
                <StarBorder className={classes.white} />
              )}
            </IconButton>
            {(userData.role === "Administrator" ||
              userData.role === "Editor" ||
              (userData.role === "Contributor" &&
                (userData._id === recipe.creator._id ||
                  userData._id === recipe.author._id))) && (
              <Button
                variant="contained"
                size="small"
                component={Link}
                to={`/recipe/${recipe.slug}/edit`}
                color="secondary"
                className={classes.editButton}
              >
                Edit Recipe
              </Button>
            )}
          </>
        )}
        <div className={classes.infoSection}>
          {recipe.authorAvatar && (
            <div className={classes.avatarSection}>
              <Avatar src={recipe.authorAvatar} className={classes.avatar} />
              <div>
                <Typography variant="h6">
                  {recipe.authorName.split(" ")[0]}
                </Typography>
                <Typography variant="h7">
                  {moment(recipe.createdAt).format("ddd D MMM YYYY")}
                </Typography>
              </div>
            </div>
          )}
          <div className={classes.infoItems}>
            {recipe.authorName && !recipe.authorAvatar && (
              <Typography className={classes.infoItem}>
                <AuthorIcon className={classes.icon} /> &nbsp;{" "}
                {recipe.authorName}
              </Typography>
            )}
            {/* {recipe.category && (
            <Typography className={classes.infoItem}>
              <CategoryIcon className={classes.icon} /> &nbsp; {recipe.category}
            </Typography>
          )} */}
            {recipe.size && (
              <Typography className={classes.infoItem}>
                {recipe.size.toLowerCase().indexOf("people") >= 0 ||
                recipe.size.toLowerCase().indexOf("person") >= 0 ? (
                  <PeopleIcon className={classes.icon} />
                ) : (
                  <ServingIcon className={classes.icon} />
                )}{" "}
                &nbsp; {recipe.size}
              </Typography>
            )}
            {(recipe.activeTime || recipe.inactiveTime) && (
              <Typography className={classes.infoItem}>
                <TimeIcon className={classes.icon} /> &nbsp;
                {formatTime(recipe.activeTime)} {recipe.inactiveTime}
              </Typography>
            )}
            {/* {recipe.cookTime && (
              <Typography className={classes.infoItem}>
                <TimeIcon className={classes.icon} /> &nbsp; Cook:{" "}
                {recipe.cookTime}
              </Typography>
            )} */}
            {recipe.difficulty && (
              <DifficultyRating
                value={recipe.difficulty}
                className={classes.infoItem}
              />
            )}
            {recipe.categoryName && (
              <Typography className={classes.infoItem}>
                <CategoryIcon className={classes.icon} /> &nbsp;{" "}
                {recipe.categoryName}
              </Typography>
            )}
            {recipe.dietaryNames && recipe.dietaryNames.length > 0 && (
              <Typography className={classes.infoItem}>
                <DietIcon className={classes.icon} /> &nbsp;{" "}
                {recipe.dietaryNames
                  .map((dn) => dn.substr(0, dn.indexOf(" (")))
                  .join(", ")}
              </Typography>
            )}
          </div>
        </div>
        <Divider className={classes.divider} />
        {validPhotos.length > 0 && (
          <div
            className={classes.imageMain}
            style={{
              backgroundImage: `url(${formatPhoto(validPhotos[0], "h")})`,
            }}
            onClick={() => setOpenGallery(validPhotos[0])}
          />
          // <img
          //   src={(recipe.photos || [])[0]}
          //   className={classes.mainImage}
          //   alt={recipe.title}
          // />
        )}
        {validPhotos.length > 1 && (
          <div>
            {validPhotos.slice(1).map((p, pI) => (
              <img
                src={formatPhoto(p, "m")}
                alt={recipe.title}
                className={classes.galleryThumb}
                onClick={() => setOpenGallery(p)}
                key={pI}
              />
            ))}
          </div>
        )}
        {validPhotos.length > 0 && <Divider className={classes.divider} />}
        {recipe.intro && (
          <>
            {recipe.intro
              .split("\n")
              .filter((l) => l)
              .map((l) => (
                <Typography className={classes.intro}>{l}</Typography>
              ))}

            <Divider className={classes.divider} />
          </>
        )}
        {
          recipe.videoUrl &&
            ytUrlToId(recipe.videoUrl) &&
            embedYoutube(ytUrlToId(recipe.videoUrl), classes.video)
          // <YouTube
          //   videoId={ytUrlToId(recipe.videoUrl)}
          //   opts={{ width: "100%", playerVars: { loop: 1 } }}
          //   className={classes.video}
          // />
        }
        {recipe.equipment && recipe.equipment.length > 0 && (
          <div className={classes.equip}>
            <Typography variant="h4" className={classes.sectionTitle}>
              Required Equipment
            </Typography>
            <ul className={classes.equipList}>
              {(recipe.equipment || []).map((equip, equipId) => (
                <li key={equipId}>
                  <Typography>{equip}</Typography>
                </li>
              ))}
            </ul>
            <Divider className={classes.divider} />
          </div>
        )}
        {(recipe.sections || []).map((section, sectionPos) => (
          <section key={sectionPos}>
            {sectionPos > 0 && <Divider className={classes.divider} />}
            <Typography variant="h4" className={classes.sectionTitle}>
              {section.title}
            </Typography>
            {section.intro && (
              <>
                <Typography className={classes.intro}>
                  {section.intro}
                </Typography>
              </>
            )}
            <div className={classes.sectionRow}>
              {(section.ingredients || []).length > 0 && (
                <div className={classes.ingredientsList}>
                  <Typography
                    variant="h5"
                    className={classes.ingredientsHeader}
                  >
                    Ingredients
                    <Select
                      value={ingredientSize}
                      onChange={(event) =>
                        setIngredientSize(event.target.value)
                      }
                      className={classes.ingredientSize}
                      // size="small"
                      // variant="outlined"
                    >
                      {[0.5, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((x) => (
                        <MenuItem value={x} key={x}>{`× ${x}`}</MenuItem>
                      ))}
                    </Select>
                    <Select
                      value={ingredientUnit}
                      onChange={(event) =>
                        setIngredientUnit(event.target.value)
                      }
                      className={classes.ingredientSize}
                    >
                      {["Original", "Metric", "Imperial"].map((x) => (
                        <MenuItem value={x.toLowerCase()}>{x}</MenuItem>
                      ))}
                    </Select>
                  </Typography>
                  <List dense>
                    {(section.ingredients || []).map(
                      (ingredient, ingredientPos) => (
                        <ListItem
                          key={ingredientPos}
                          role={undefined}
                          dense
                          // disableGutters
                          button
                          onClick={() =>
                            setSelectedIngredients((si) => [
                              ...(si || []).filter(
                                (i) => i !== `${sectionPos}-${ingredientPos}`
                              ),
                              ...((si || []).indexOf(
                                `${sectionPos}-${ingredientPos}`
                              ) >= 0
                                ? []
                                : [`${sectionPos}-${ingredientPos}`]),
                            ])
                          }
                          className={classes.ingredientsListItem}
                        >
                          <ListItemIcon
                            classes={{ root: classes.listItemIcon }}
                          >
                            <Checkbox
                              edge="start"
                              checked={
                                selectedIngredients.indexOf(
                                  `${sectionPos}-${ingredientPos}`
                                ) >= 0
                              }
                              tabIndex={-1}
                              disableRipple
                              color="primary"
                              className={classes.ingredientsCheckbox}
                              //  inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={formatIngredient(
                              ingredient,
                              ingredientSize,
                              ingredientUnit
                            )}
                            className={classes.ingredientsText}
                          />
                        </ListItem>
                      )
                    )}
                  </List>
                </div>
              )}
              {
                section.method && (
                  <div className={classes.sectionContent}>
                    {!methodHasHeader(section.method) && (
                      <Typography
                        variant="h5"
                        className={classes.sectionHeader}
                      >
                        Method
                      </Typography>
                    )}
                    <div
                      className="rteContent"
                      dangerouslySetInnerHTML={{
                        __html: draftToHtml(getMethod(section.method)),
                      }}
                    />
                  </div>
                )

                //   <MUIRichTextEditor
                //     defaultValue={convertToRaw(
                //       section.method.getCurrentContent()
                //     )}
                //     readOnly
                //   />
              }
            </div>
            {section.notes && (
              <Typography className={classes.sectionNote}>
                {section.notes}
              </Typography>
            )}
            {
              section.videoUrl &&
                ytUrlToId(section.videoUrl) &&
                embedYoutube(ytUrlToId(section.videoUrl), classes.video)
              // <YouTube
              //   videoId={ytUrlToId(section.videoUrl)}
              //   opts={{ width: "100%", playerVars: { loop: 1 } }}
              //   className={classes.video}
              // />
            }
            {section.photos &&
              section.photos.filter((photo) => photo).length > 0 && (
                <div className={classes.imageContainer}>
                  {section.photos
                    .filter((photo) => photo)
                    .map((photo, photoIndex) => (
                      <div
                        key={photoIndex}
                        className={clsx(
                          classes.imageMain,
                          section.photos.length === 1
                            ? classes.none
                            : classes.imageThumb
                        )}
                        style={{
                          backgroundImage: `url(${formatPhoto(
                            photo,
                            section.photos.length === 1 ? "h" : "l"
                          )})`,
                        }}
                        onClick={() => setOpenGallery(photo)}
                      />
                    ))}
                </div>
              )}
          </section>
        ))}
        <Divider className={classes.divider} />
        {(recipe.tags || []).map((tag) => (
          <Chip
            label={tag}
            size="small"
            key={tag}
            color="secondary"
            className={classes.chip}
          />
        ))}
      </div>
      {allPhotos.filter((src) => photoDimensions[src]).length > 0 && (
        <PhotoSwipe
          isOpen={openGallery !== false}
          items={allPhotos
            .filter((src) => photoDimensions[src])
            .map((src) => ({
              src,
              w: photoDimensions[src][0],
              h: photoDimensions[src][1],
              // title: "blah",
            }))}
          options={{
            index: allPhotos
              .filter((src) => photoDimensions[src])
              .indexOf(openGallery),
          }}
          onClose={() => setOpenGallery(false)}
        />
      )}
    </div>
  );
};

RecipeView.propTypes = {
  recipe: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  // resetRecipe: PropTypes.func.isRequired,
  // setRecipeId: PropTypes.func.isRequired,
};
RecipeView.defaultProps = {
  loading: false,
};

export default RecipeView;
