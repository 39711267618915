import {
  CircularProgress,
  Container,
  makeStyles,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  flexColContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(4),
  },
  flexColItem: {
    marginBottom: theme.spacing(4),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  flexRowContainer: {
    display: "flex",
    alignItems: "center",
  },
  flexRowItem: {
    margin: theme.spacing(0, 2, 0, 0),
  },
}));

const ErrorPage = () => {
  const classes = useStyles();

  return (
    <Container className={clsx(classes.flexColContainer)} maxWidth="md">
      <Typography className={classes.flexColItem} variant="h5">
        Page not found
      </Typography>
      <Typography className={classes.flexColItem} variant="h5">
        <CircularProgress />
      </Typography>
    </Container>
  );
};

export default ErrorPage;
