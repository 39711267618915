import {
  Avatar,
  Container,
  Divider,
  makeStyles,
  Typography,
  Box,
} from "@material-ui/core";

import granmum from "./img/granmum.jpg";
import fairy from "./img/steve-fairy.jpg";

const useStyles = makeStyles((theme) => ({
  flexColContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(10),
  },

  container: {
    width: "100%",
    minHeight: `calc(100vh - ${theme.spacing(7)}px)`,

    maxWidth: 870,
    padding: "0px 20px",
    paddingTop: theme.spacing(4),
    backgroundSize: "110%",
    marginTop: theme.spacing(7),
    [theme.breakpoints.up("md")]: {
      maxWidth: 1150,
      padding: "0px 140px",
      paddingTop: theme.spacing(4),
      backgroundSize: "100%",
      marginTop: theme.spacing(8),
      minHeight: `calc(100vh - ${theme.spacing(8)}px)`,
      paddingBottom: 40,
    },
    margin: "0px auto",
    backgroundImage:
      theme.palette.type === "light"
        ? "url(/background-light.jpg)"
        : "url(/background-dark.jpg)",
    backgroundPosition: "center",
    backgroundRepeatY: "repeat",
    // marginTop: -8,
    paddingBottom: 40,
    // marginBottom: theme.spacing(5),
    // textAlign: "center",
    // marginTop: theme.spacing(4),
  },
  flexColItem: {
    marginBottom: theme.spacing(4),
  },
  flexRowContainer: {
    display: "flex",
    alignItems: "center",
  },
  flexRowItem: {
    margin: theme.spacing(0, 2, 0, 0),
  },
  avatar: {
    width: theme.spacing(30),
    height: theme.spacing(30),
    margin: "0 auto",
    marginBottom: theme.spacing(4),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  p: {
    margin: theme.spacing(3, 0),
  },
  imgs: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexWrap: "wrap",
  },
  img: {
    height: 250,
    margin: theme.spacing(1),
  },
  fairySplit: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "flex-start",
    },
  },
  fairyImg: {
    margin: theme.spacing(2),
    maxWidth: "100vw",
    width: 300,
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(1, 0, 0, 4),
    },
  },
}));

const AboutPage = ({ ...rest }) => {
  const classes = useStyles();

  return (
    <Container className={classes.container} maxWidth="sm">
      {/* <Typography className={classes.flexColItem} variant="h3">
        About
      </Typography> */}

      <Avatar src={granmum} className={classes.avatar} />

      <Typography variant="h4" align="center" className={classes.p}>
        Dedicated to Anne-Marie Randle
      </Typography>
      <Typography variant="h5" align="center" className={classes.p}>
        ‘Mum, Granmum, Great Granmum’
      </Typography>
      <Typography variant="h6" className={classes.p}>
        Whose inspired creativity and love, fed us body and mind. This platform
        contains many of her life recipes to pass on her love of food to kin and
        clan, and celebrate the tradition of cooking, sharing and loving in the
        kitchen.
      </Typography>
      <Typography variant="h6" className={classes.p}>
        <strong>‘Cook, Share, Love’</strong>
      </Typography>
      <Divider className={classes.divider} />
      <Typography className={classes.p}>
        “We may all be across the world 🌎 from each other but we will always be
        in each other’s kitchens, which our beautiful Mum would be so overjoyed
        at. Everyone’s kitchen should smell like hers did!” - 🍃Fiona 🍃
      </Typography>
      <Divider className={classes.divider} />

      <div className={classes.imgs}>
        <img
          src="https://i.imgur.com/TqAgSbDl.jpg"
          className={classes.img}
          alt="Family group"
        />
        <img
          src="https://i.imgur.com/Ht1jeqfl.jpg"
          className={classes.img}
          alt="Family"
        />
      </div>

      <Typography className={classes.p}>
        Randle Recipes is a web-based collaborative recipe platform.
      </Typography>
      <Typography className={classes.p}>
        The project was conceived in 2015 after flicking through one of Mum’s
        cookbooks around the dining table at HQ46. The concept was to create an
        online tool for us all to store and share our family recipes. The
        vision: to celebrate the love of Mum's kitchen, and the sense of family
        occasion and unity she created through it. This, I felt, must be
        preserved and shared by the whole family for future generations.
      </Typography>
      <Typography className={classes.p}>
        After Mum’s passing in 2018, the project became front of mind.
      </Typography>
      <Typography className={classes.p}>
        Many planning and design concepts were discussed. After months of
        development and prototyping on several platforms, we have ended up with
        ‘Randle Recipes’. We hope you enjoy the platform and continue the family
        traditions in and around the kitchen that Mum treasured so dearly.
      </Typography>

      <Divider className={classes.divider} />

      <Box className={classes.fairySplit}>
        <Box>
          <Typography variant="h5">
            “Faery Grandmother Owl and her Daughter Cat”
          </Typography>

          <Typography className={classes.p}>
            Once Upon a Time.... there were two Fareys, Grandmother Owl and her
            Daughter Cat. They were entrusted with teaching the “Spell of
            Cookery”.
          </Typography>
          <Typography className={classes.p}>
            As they danced by the light of the Silvery Moon, they would take
            some Honey, and with very little money, turn the Honey into Gold.
          </Typography>
          <Typography className={classes.p}>
            Word soon spread across the land, and people would come from far and
            wide to be dazzled and bewitched by the “Love of Cookery” these
            Faery’s had.
          </Typography>
          <Typography className={classes.p}>
            And in time, Faery Cat was blessed with a Son, Stephen, who was also
            “enchanted” with the Love of Cookery.
          </Typography>
          <Typography className={classes.p}>
            By the light of Silvery Moon and the Stars above, Owl and Cat
            instilled in Stephen, that each recipe is actually a “Magical
            Spell”. And one needed to gather the ingredients together all bound
            within the magic of Love...... knowing it takes time and skill to
            create the Alchemy of turning Honey into Gold.
          </Typography>
          <Typography className={classes.p}>
            They dined on Honey and Mince, with slices of Quince, Which they ate
            with a runcible spoon, and hand in hand, on the edge of the sand,
            they all danced and ate by the light of the Silvery Moon,
            <br />
            the Moon,
            <br />
            the Moon,
            <br />
            and they still dance and eat Honey by the light of the Silvery Moon.
          </Typography>
        </Box>
        <Box>
          <img src={fairy} alt="Fairies" className={classes.fairyImg} />
        </Box>
      </Box>

      <Divider className={classes.divider} />

      <Typography variant="h6" className={classes.p}>
        Credits
      </Typography>
      <Typography className={classes.p}>
        <strong>Platform Construction:</strong> Massive thanks to Max and Chris;
        without their admirable software engineering talents and patience, this
        would not have been possible.
      </Typography>
      <Typography className={classes.p}>
        <strong>Creative: Roger:</strong> Platform concept, editorial and
        design, chief whip.
      </Typography>
      <Typography className={classes.p}>
        <strong>Editing: Pourri:</strong> Proofreading, editing and sanity
        monitor.
      </Typography>
      <Typography className={classes.p}>
        <strong>Mum’s Cookbook Photos:</strong> Fiona
      </Typography>
    </Container>
  );
};

export default AboutPage;
