import { createValidationFunction } from "../helperFunctions";
import { FreeTextInput, PasswordInput } from "../input_components";

const email = {
  label: "Email",
  toString: (data) => data.email,
  validate: createValidationFunction([
    {
      test: (data) => data.email !== "",
      feedback: "field is required",
    },
  ]),
  component: FreeTextInput,
  componentProps: { required: true },
};

const password = {
  label: "Password",
  toString: (data) => data.password,
  validate: createValidationFunction([
    {
      test: (data) => data.password !== "",
      feedback: "field is required",
    },
  ]),
  component: PasswordInput,
  componentProps: { required: true },
};

export const schema = { email, password };

export const blankForm = {
  email: "",
  password: "",
};
