import React, { useMemo, useContext } from "react";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// import { amber, green } from "@material-ui/core/colors";
// import "@fontsource/ledger";
// import ApiTest from "./ApiTest";
import LoginPage from "./LoginPage";
// import UserDataContextProvider from "./UserDataContext";
import { CssBaseline } from "@material-ui/core";
import { Route } from "react-router-dom";
import AuthenticatedRoute from "./AuthenticatedRoute";
// import AdminConsole from "./AdminConsole";
// import AdminRoute from "./AdminRoute";
// import Dashboard from "./Dashboard";
// import RecipeRouter from "./RecipeRouter";
// import SettingsPage from "./SettingsPage";
import App from "./App";
import { UserDataContext } from "./UserDataContext";
// import AdminRoute from "./AdminRoute";

const AppWrapper = () => {
  const {
    userData,
    // updateSelfUserDetails,
    // updateSelfUserPreferences,
  } = useContext(UserDataContext);
  // console.log("userData", userData);
  // const [themeMode, setThemeMode] = useState(false);
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const darkMode =
    (userData?.preferences?.theme || "system") === "system"
      ? prefersDarkMode
      : userData?.preferences?.theme === "dark";
  const theme = useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: darkMode ? "dark" : "light",
          primary: {
            // main: pink[darkMode ? 300 : 500], // this colour offends my masculinity
            main: "#62a5a1", // green[darkMode ? 900 : 700],
          },
          secondary: {
            main: darkMode ? "#ce63a0" : "#b54484", // amber[darkMode ? 600 : 900],
          },
        },
        overrides: {
          MuiButton: {
            root: {
              fontFamily: ['"Montserrat"', "sans-serif"],
              fontWeight: 500,
              textTransform: "uppercase",
            },
          },
          MuiChip: {
            root: {
              fontFamily: ['"Montserrat"', "sans-serif"],
              fontWeight: 700,
              textTransform: "uppercase",
              fontStyle: "normal",
            },
          },
          MuiGridListTile: {
            imgFullHeight: {
              transition: "0.2s height",
              "&:hover": {
                height: "105%",
              },
            },
            imgFullWidth: {
              transition: "0.2s width",
              "&:hover": {
                width: "105%",
              },
            },
          },
          MUIRichTextEditor: {
            editor: {
              border: `1px solid ${
                darkMode ? "rgba(255, 255, 255, 0.23)" : "rgba(0, 0, 0, 0.23)"
              }`,
              borderRadius: 4,
              padding: "0 14px",
              minHeight: 37,
            },
            placeHolder: {
              marginLeft: 14,
              fontStyle: "italic",
            },
          },
        },
        typography: {
          fontFamily: ['"Average"', "serif"],
        },
      }),
    [darkMode]
  );

  // whats this for?
  // useEffect(() => {
  //   document.body.style.backgroundColor = theme.palette.background.paper;
  //   document.body.style.color = theme.palette.text.primary;
  // }, [theme]);

  // Object.assign(theme, {
  //   overrides: {
  //     ...theme,
  //     MUIRichTextEditor: {
  //       editor: {
  //         border: `1px solid ${
  //           darkMode ? "rgba(255, 255, 255, 0.23)" : "rgba(0, 0, 0, 0.23)"
  //         }`,
  //         borderRadius: 4,
  //         padding: "0 14px",
  //         minHeight: 37,
  //       },
  //       placeHolder: {
  //         marginLeft: 14,
  //         fontStyle: "italic",
  //       },
  //     },
  //   },
  // });

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Route path="/login">
        <LoginPage />
      </Route>

      <AuthenticatedRoute path="*" redirectPath="/login">
        <App darkMode={darkMode} />
      </AuthenticatedRoute>
    </MuiThemeProvider>
  );
};

export default AppWrapper;
